import AAFaculty from "./AAFaculty";
import AFDFaculty from "./AFDFaculty";
import LibraryFaculty from "./LibraryFaculty";
import DistanceStudents from "./DistanceStudents";
import PostStudents from "./PostStudents";
import UnderStudents from "./UnderStudents";
import React, { useRef, useState, useEffect } from "react";
import PhdFaculty from "./PhDFaculty";
import { Flex } from "../../ui";
import { FaChevronDown } from "react-icons/fa";
import Graduates from "./Gratuates";

const OrganizationBody = () => {
  const [selectedSection, setSelectedSection] = useState(<PhdFaculty />);
  const [showFacultyVariables, setShowFacultyVariables] = useState(false);
  const [showStudentsVariables, setShowStudentsVariables] = useState(false);
  const [selectedNav, setSelectedNav] = useState("Faculty"); // Corrected typo
  const toggleOutsideHideRef = useRef(null);

  // Adds event listener to detect clicks outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleOutsideHideRef.current &&
        !toggleOutsideHideRef.current.contains(event.target)
      ) {
        setShowFacultyVariables(false);
        setShowStudentsVariables(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getNavItemStyle = (section) => {
    return section === selectedNav
      ? "active-selection"
      : "text-blue-950 font-semibold text-base hover:text-blue-500";
  };
  return (
    <>
      <Flex
        direction="col"
        align="start"
        justify="start"
        className="pl-32 pr-32 mobile-margin-x-zero pb-12">
        <div ref={toggleOutsideHideRef} className="w-full">
          <Flex
            direction="col"
            justify="start"
            align="start"
            className="max-h-[110px] w-full mb-10">
            <ul
              className="flex flex-row justify-start items-start gap-10  w-full h-full"
              style={{
                borderBottom: "2px solid rgba(0, 51, 102, 1)",
              }}>
              <li>
                {" "}
                <Flex
                  direction="col"
                  justify="start"
                  align="start"
                  className="gap-2">
                  <div
                    onClick={() => {
                      setShowFacultyVariables(!showFacultyVariables);
                      setShowStudentsVariables(false);
                      setSelectedNav("Faculty");
                    }}
                    className={getNavItemStyle("Faculty")}
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      letterSpacing: "0.48px",
                      paddingTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "8px",
                    }}>
                    Faculty <FaChevronDown size={15} className="ml-2" />
                  </div>
                  <div
                    className="flex flex-col w-full absolute rounded-xl mt-12 justify-start max-w-96  items-center"
                    style={{
                      boxShadow: "0px 2px 4px 0px #294A70",
                      // border: "2px dashed  #294A70",

                      display: `${showFacultyVariables ? "block" : "none"}`,
                    }}>
                    <button
                      className="w-full h-10  rounded-t-xl bg-white border-b-2 border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<PhdFaculty />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Faculty (Ph.d)
                    </button>
                    <button
                      className="w-full h-10 bg-white border-b-2 border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<AFDFaculty />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Admin & Finance Department
                    </button>
                    <button
                      className="w-full h-10 bg-white border-b-2 border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<AAFaculty />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Academic Affairs Department
                    </button>
                    <button
                      className="w-full h-10 bg-white  rounded-b-xl flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<LibraryFaculty />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Library Department
                    </button>
                  </div>
                </Flex>
              </li>
              <li>
                {" "}
                <Flex
                  direction="col"
                  justify="start"
                  align="start"
                  className="gap-2">
                  <div
                    onClick={() => {
                      setShowFacultyVariables(false);
                      setShowStudentsVariables(!showStudentsVariables);
                      setSelectedNav("Students");
                    }}
                    className={getNavItemStyle("Students")}
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      letterSpacing: "0.48px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingTop: "10px",
                      gap: "8px",
                    }}>
                    Students <FaChevronDown size={15} className="ml-2" />
                  </div>
                  <div
                    className="flex flex-col w-full absolute rounded-xl mt-12 justify-start max-w-96  items-center"
                    style={{
                      boxShadow: "0px 2px 4px 0px #294A70",
                      // border: "2px dashed  #294A70",
                      display: `${showStudentsVariables ? "block" : "none"}`,
                    }}>
                    <button
                      className="w-full h-10 bg-white border-b-2 rounded-t-xl border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<Graduates />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Graduate Students
                    </button>{" "}
                    <button
                      className="w-full h-10 bg-white border-b-2 border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<UnderStudents />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Under Graduate Students
                    </button>
                    <button
                      className="w-full h-10 bg-white border-b-2 border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<PostStudents />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Post Graduate Students
                    </button>
                    <button
                      className="w-full h-10 bg-white border-b-2  rounded-b-xl border-dashed flex justify-center items-center text-black hover:bg-blue-950 hover:text-white"
                      onClick={() => {
                        setSelectedSection(<DistanceStudents />);
                        setShowFacultyVariables(false);
                        setShowStudentsVariables(false);
                      }}>
                      Distance Education Students
                    </button>
                  </div>
                </Flex>
              </li>
            </ul>
          </Flex>
        </div>
        <Flex
          direction="col"
          justify="start"
          align="start"
          className=" whitespace-normal min-h-auto  gap-0">
          {selectedSection}
        </Flex>
      </Flex>
    </>
  );
};

export default OrganizationBody;
