import {
  DegreeBody,
  DegreeContactInfo,
  // DegreeFooterSection,
  DegreeHeading,
} from "../components";
import { Image, Text, Flex } from "../ui";
import { instructors, students } from "../assets";

function Degree() {
  return (
    <>
      <DegreeHeading />
      <div className="mobile-margin-x-zero ml-32 mr-32">
        <DegreeBody />
        {/* <DegreeFooterSection /> */}
        <Flex
          direction="row"
          className="mobile-column p-8 rounded-3xl border-2 border-t-8 border-t-yellow-400 border-blue-950">
          <Flex direction="row" justify="center" items="center">
            <Flex
              direction="col"
              align="center"
              justify="between"
              className="border-r-2 w-1/2 border-r-gray-200">
              <Image
                src={students}
                alt="Graduated Students"
                width="51px"
                height="51px"
                className="mb-3"
              />
              <Text content="Graduated Students" size="18px" weight="400" />
              <Text content="500" size="30px" weight="400" />
            </Flex>
            <Flex
              direction="col"
              align="center"
              justify="between"
              className="border-r-2 w-1/2 border-r-gray-200">
              <Image
                src={instructors}
                alt="Attend Students"
                width="51px"
                height="51px"
                className="mb-3"
              />
              <Text content="Attend Students" size="18px" weight="400" />
              <Text content="200" size="30px" weight="400" />
            </Flex>
          </Flex>{" "}
          <div
            className="h-full w-full text-justify"
            style={{
              backgroundColor: "rgba(41, 74, 112, 1)",
              color: "white",
              padding: "20px",
              fontSize: "17px",
              boxShadow: "0px 4px 4px 0px skyblue",
            }}>
            The tuition fee for the Bachelor degree, Honours degree, and Master
            degree for any programme in MUE are 9950 mmk.  The Ph.D programme in
            MUE cost 27650 mmk. The tution fee for any master degree programme
            (online ) is 245000 mmk and for any diploma course (online) is
            180000 mmk in MUE. <br />
            <button className="text-blue-950 mt-3 bg-white rounded-3xl pl-5 pr-5 pt-3 pb-3 hover:bg-yellow-400 hover:text-white hover:duration-500">
              Enroll Today
            </button>
          </div>
        </Flex>
        <DegreeContactInfo />
      </div>
    </>
  );
}

export default Degree;
