import OrganizationBody from "../components/organization/OrganizationBody";
import { Text } from "../ui";

function Organization() {
  return (
    <>
      <div className="pl-32 pt-10 pr-32 mobile-margin-x-zero">
        <Text
          content="Statistical Informations"
          size="30px"
          weight="400"
          color="rgba(41, 74, 112, 1)"
        />

        <div
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
          className="h-1 w-11 hover:w-32 hover:duration-1000 mt-3 mb-3"></div>
      </div>
      <OrganizationBody />
    </>
  );
}

export default Organization;
