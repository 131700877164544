import React from "react";
import { Flex, Text } from "../../ui";

const MasterDegree = () => {
  return (
    <>
      <Flex
        direction="col"
        justify="center"
        items="center"
        className="p-8 max-w-[875px] rounded-3xl border-2 border-t-8 border-t-yellow-400 border-blue-950">
        <Text
          content="There are two kinds of master degree programmes namely regular degree programme, and professional degree programme. The candidates who are eligible to apply for registration for master degree programmes are as follow:"
          color="black"
          className="p-3 mb-5"
          backgroundColor="rgba(255, 215, 0, 1)"
        />
        <Flex direction="row" justify="start" align="start">
          <Flex
            direction="col"
            justify="start"
            align="start"
            className="text-justify">
            <Text
              content="Regular Master Degree programme"
              color="black"
              weight="500"
              size="20"
              className="text-left"
            />
            <div
              className=" mt-3 h-1 w-16 mb-3  hover:w-40 hover:duration-1000"
              style={{ backgroundColor: "rgba(51, 51, 51, 1)" }}></div>
            <Text
              content="A graduate of the Institute who has passed honours examination or the master qualifying examination with Grade Point 4 in each subject is eligible to join master degree programme. A student must follow an approved course of study for a period not less than one year from the date of registration. Master examination shall consist of both written and viva voce. In addition, the candidate is required to submit a thesis. The thesis must be submitted within three years from the date of registration. If the candidate fails to submit the thesis within the given time, the written examination which he or she has taken will be treated as void."
              color="black"
              backgroundColor="transparent"
            />
          </Flex>
          <div
            className="w-1 h-96 m-5"
            style={{ backgroundColor: "rgba(0, 51, 102, 0.5)" }}></div>
          <Flex
            direction="col"
            justify="start"
            align="start"
            className="text-justify">
            <Text
              content=" Professional Master Degree Programme"
              color="black"
            />
            <div
              className="h-1 w-16 m-3 ml-1  hover:w-40 hover:duration-1000"
              style={{ backgroundColor: "rgba(51, 51, 51, 1)" }}></div>
            <Text
              content="Yangon University of Economics offers three kinds of professional master degree programme_ Full-time Master Programme, Executive-level Master Programme, and Online Master Programme."
              color="black"
              backgroundColor="transparent"
              className="mb-2"
            />
            <Text
              content="Participants are selected from a broad spectrum of disciplines, diverse background with varying aims and career objectives. Admission requirements to the programme are a Bachelor’s Degree from any recognized university, a pass in GMAT-like (Graduate Management Admission Test) examination and satisfactory interview results."
              color="black"
              backgroundColor="transparent"
              className="mb-2"
            />
            <Text
              content="Details of the course curriculum, contents, credit system of examination, teaching methods, faculty and time schedule are available in a separate prospectus for MBA, MPA, MDevS, MBF, MAS, MMM and MHTM."
              color="black"
              backgroundColor="transparent"
              className="mb-2"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default MasterDegree;
