import React from "react";
import "./index.css"; // Add styles for your table in this file

const Table = ({
  topHeaders = [],
  headers = [],
  data = [],
  width = "auto",
  height = "auto",
  className = "",
}) => {
  return (
    <table style={{ width, height }} className={className}>
      <thead>
        {" "}
        <tr>
          {topHeaders.map((topHeader, index) => (
            <th key={index} colSpan={2} className="bg-yellow-400">
              {topHeader}
            </th>
          ))}
        </tr>{" "}
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr className="pl-10 pr-10" key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td className="pl-16 pr-16" key={cellIndex}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
