import React from "react";
import { Flex, Text } from "../ui";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsCircleFill } from "react-icons/bs";

export const ClickDropDown = ({
  info,
  detail,
  showDetail,
  setShowDetail,
  detailGroup,
  toggleClick,
}) => {
  return (
    <>
      <div onClick={toggleClick}>
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="department-vision rounded-3xl min-w-80 max-w-[330px]  w-full shadow-md m-3 ml-0 ">
          <div
            className="cursor-pointer rounded-3xl p-1 items-center pr-3 h-13 w-full flex flex-row justify-between"
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
            onClick={() => setShowDetail(!showDetail)}>
            <Text
              content={info}
              color="rgba(255, 255, 255, 1)"
              backgroundColor="transparent"
              className="pl-5 pr-5 p-1 rounded-xl"
            />
            {showDetail ? (
              <FaChevronUp
                size={20}
                color="white"
                className="duration-1000 ease-in-out"
              />
            ) : (
              <FaChevronDown
                size={20}
                color="white"
                className="duration-1000 ease-in-out"
              />
            )}
          </div>
          {showDetail && detail && (
            <div className="flex flex-row gap-2 justify-start items-start">
              {" "}
              <BsCircleFill
                size={5}
                className="min-w-1 min-h-1 mt-5 ml-3"
                color="rgba(0,0,0,0.8)"
              />
              <Text
                color="rgba(0, 0, 0, 0.8)"
                content={detail}
                size="14px"
                weight="500"
                className="text-justify rounded-b-xl p-3 duration-1000 ease-in-out"
              />
            </div>
          )}
          {detailGroup &&
            showDetail &&
            detailGroup.map((detailInfo, index) => (
              <div
                className="flex flex-row gap-2 justify-start items-start"
                key={index}>
                {" "}
                <BsCircleFill
                  size={5}
                  className="min-w-1 min-h-1 mt-5 ml-3"
                  color="rgba(0,0,0,0.8)"
                />
                <Text
                  color="rgba(0, 0, 0, 0.8)"
                  content={detailInfo}
                  size="14px"
                  weight="500"
                  className="text-justify rounded-b-xl p-3 duration-1000 ease-in-out"
                />
              </div>
            ))}
        </Flex>
      </div>
    </>
  );
};
