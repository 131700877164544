import React from "react";
import "./index.css";

const Text = ({
  content = "",
  size = "16px",
  color = "#294A70;",
  weight = "normal",
  backgroundColor = "white",
  className = "",
  ...props
}) => {
  return (
    <p
      style={{
        fontSize: size,
        color: color,
        fontWeight: weight,
        backgroundColor: backgroundColor,
      }}
      {...props}
      className={className}>
      {content}
    </p>
  );
};

export default Text;
