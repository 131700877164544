import React from "react";
const CommerceActivities = ({ activities }) => {
  return (
    <>
      <div className="m-4 p-8  justify-center items-center w-full rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950 flex flex-col">
        <div className="flex flex-wrap justify-start flex-row md:gap-4 xl:gap-8 items-end">
          {activities.map((activity, index) => (
            <div
              key={index}
              className="mobile-scale-08 "
              style={{ maxWidth: "294px" }}>
              <p className="text-base font-normal" color="rgba(51, 51, 51, 1)">
                {activity.title}
              </p>
              <div
                className="mt-5 translate-y-2"
                style={{ backgroundColor: "#49688E" }}>
                <img
                  src={activity.image}
                  alt={activity.title}
                  style={{
                    width: "292px",
                    height: "185px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  className=" -translate-y-2 -translate-x-2"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CommerceActivities;
