import React, { useState } from "react";
import HonoursDegree from "./HonoursDegree";
import MasterDegree from "./MasterDegree";
import DoctoralDegrees from "./DoctoralDegrees";
import DiplomaDegree from "./DiplomaDegree";
import { Flex } from "../../ui";

const DegreeTypes = () => {
  const [selectedSection, setSelectedSection] = useState("HonoursDegrees");

  const getNavItemStyle = (section) => {
    return section === selectedSection
      ? "active-selection"
      : "text-gray-500 hover:text-blue-500";
  };
  return (
    <>
      <Flex direction="col" align="start" justify="start">
        {/* Navigation bar */}
        <Flex justify="start" className=" max-h-[110px] mb-10">
          <ul
            className="flex flex-row gap-10 w-full h-full"
            style={{
              borderTop: "1px dashed gray",
              borderBottom: "2px solid",
            }}>
            <li
              onClick={() => setSelectedSection("HonoursDegrees")}
              className={getNavItemStyle("HonoursDegrees")}>
              Honours Degrees
            </li>
            <li
              onClick={() => setSelectedSection("MasterDegrees")}
              className={getNavItemStyle("MasterDegrees")}>
              Master Degrees
            </li>
            <li
              onClick={() => setSelectedSection("DoctoralDegrees")}
              className={getNavItemStyle("DoctoralDegrees")}>
              Doctoral Degrees
            </li>
            <li
              onClick={() => setSelectedSection("Diplomas")}
              className={getNavItemStyle("Diplomas")}>
              Diplomas
            </li>
          </ul>
        </Flex>

        {/* Conditional rendering of components */}
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="min-w-[1179px] min-h-[300px] ">
          {selectedSection === "HonoursDegrees" && <HonoursDegree />}
          {selectedSection === "MasterDegrees" && <MasterDegree />}
          {selectedSection === "DoctoralDegrees" && <DoctoralDegrees />}
          {selectedSection === "Diplomas" && <DiplomaDegree />}
        </Flex>
      </Flex>
    </>
  );
};

export default DegreeTypes;
