import React from "react";
import { Text, Table } from "../../ui";
const LibraryFaculty = () => {
  const headers = ["Position", "No."];
  const data = [
    ["Library Assistant (2) ", 1],
    ["Library Assistant (3) ", 1],
    ["Library Assistant (4) ", 3],
    ["Library Assistant (5) ", 2],
    ["Clean Worker", 1],
    ["Total", 8],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      <Text
        content="Library Department"
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table headers={headers} data={data} className="aa-faculty-table" />
    </div>
  );
};

export default LibraryFaculty;
