import { ContactHeader, FindOnMaps, SendMessage } from "../components";

function Contact() {
  return (
    <>
      <ContactHeader />
      <SendMessage />
      <FindOnMaps />
    </>
  );
}

export default Contact;
