import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import {
  mmActi1,
  mmActi2,
  mmActi3,
  mmActi4,
  mmActi5,
  mmActi6,
  mmActi7,
  mmActi8,
  mmActi9,
  mmActi10,
  mmActi11,
  mmActi12,
} from "../../assets";
import ManagementLogo from "../../assets/departmentLogo/Myanmarsar Logo.jpg";

const MyanmarDepartmentHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const images = [
    mmActi1,
    mmActi2,
    mmActi3,
    mmActi4,
    mmActi5,
    mmActi6,
    mmActi7,
    mmActi8,
    mmActi9,
    mmActi10,
    mmActi11,
    mmActi12,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const objectivesData = [
    "To learn about Myanmar Literature and Language",
    "To develop a love for Myanmar cultural customs",
    "To learn to distinguish between good and bad for improving their life",
    "To develop the spirit of wanting to contribute as much as possible to improve the living standards of their country and ethnic groups",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="start"
          className="text-justify ">
          <HeadingCard
            title="Department of Myanmar"
            departmentLogo={ManagementLogo}
          />
          <Text
            content="The department of Myanmar is one of the supporting departments of Monywa University of Economics."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="	Myanmar subject and Aspects of Myanmar are taught to all 1st year economics students."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="	In the Myanmar subject includes the prose, poetry and language. In addition, the students can learn how to write office letters in their real lives."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="	Moreover, the students can learn about literature, language, ancient cities and famous regions, traditional culture and history of education in Aspects of Myanmar."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="To become good and polite people who love their country through their love for Myanmar Literature and Myanmar Culture."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="To gain knowledge and wisdom by studying Myanmar prose and Myanmar poetry, to learn the Myanmar Language property and apply it practically and to become good citizens with good morals and correct thinking."
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesData}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default MyanmarDepartmentHeading;
