import { Flex, Image, Text } from "../../ui";

function CommerceOurHead({ headInfo }) {
  return (
    <>
      <div
        className="mb-10"
        style={{
          boxShadow: "0px 2px 4px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
          padding: "20px",
        }}>
        <Text
          content="Our Head"
          size="20px"
          weight="600"
          color="#333"
          className="mb-5 "
        />
        <Flex
          direction="row"
          align="start"
          justify="start"
          className="gap-10 mobile-column">
          {" "}
          <Image
            src={headInfo.profileImage}
            height="250px"
            width="auto"
            alt={headInfo.name}
            className="ml-5"
          />
          <Flex direction="col" justify="start" align="start">
            <Text
              content={headInfo.name}
              size="24px"
              weight="600"
              className="mb-2 text-left"
            />
            <Text content="Professor" size="20px" weight="500" />
            <Text
              content={headInfo.department}
              size="20px"
              weight="400"
              className="text-left"
            />
          </Flex>
        </Flex>
        <Text
          size="18px"
          color="#333"
          content={headInfo.passage}
          className="text-justify mobile-margin-x-zero pr-9 pl-9 pt-7 pb-7"
        />
      </div>
    </>
  );
}

export default CommerceOurHead;
