import React from "react";
import { Flex, Text } from "../../ui";
import { PiDiamondsFourFill } from "react-icons/pi";

const DiplomaDegree = () => {
  return (
    <>
      <Flex
        direction="col"
        justify="center"
        items="center"
        className="p-8 max-w-[875px] rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950">
        <Text
          content="Applicants for admission to courses for Diplomas must satisfy the following requirements:"
          size="18px"
          color="black"
          className="text-justify"
        />
        <div className="flex flex-row gap-3">
          <PiDiamondsFourFill size={20} className="mr-3" />
          <p className="text-left">
            The applicant must be at least 25 years old. The applicant must be a
            graduate of recognized university, and must have at least three
            years of working experience.
          </p>
        </div>
        <div className="flex flex-row gap-3">
          <PiDiamondsFourFill size={20} className="mr-3" />
          <p className="text-left">
            Applicants for admission to the Diploma courses are required to take
            entrance examinations. Students of Diploma classes are required to
            submit term papers.
          </p>
        </div>
      </Flex>
    </>
  );
};

export default DiplomaDegree;
