import React from "react";
import { Flex, Text } from "../../ui";
import { PiDiamondsFourFill } from "react-icons/pi";

const HonoursDegree = () => {
  return (
    <>
      <Flex
        direction="col"
        items="start"
        justify="start"
        className="p-8 max-w-[875px] rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950">
        <Text
          content="The students, who have passed the second year examination with Grade Points 5 in two core subjects and total Grade Point Average (GPA) for two semesters is required to get GPA 4 and above, are eligible to apply for registration for a Honours classes. Duration of Honours degree courses is 3 years. If the two years for the first year and second year are taken into consideration, the total period of study will be five years. Honours students are permitted to appear for the examination only once. Honours examination consists of both written and viva voce examination. The candidate is able to submit a dissertation. In the written examination, the student must have Grade Point 4 in each subject. The students who cannot fulfill the requirements of written and dissertation will obtain only ordinary degree without honours. Examination results are published according to the following five classes:"
          color="black"
          size="16px"
          className="text-justify mb-5"
        />
        <Flex justify="start" direction="row">
          <PiDiamondsFourFill size={20} className="mr-2" />
          <p>To generate qualified intellectuals and experts in Economics</p>
        </Flex>
        <Flex justify="start" direction="row">
          <PiDiamondsFourFill size={20} className="mr-2" />
          <p>
            To nurture good citizens who can criticize, review and evaluate the
            cause and effect
          </p>
        </Flex>
        <Flex justify="start" direction="row">
          <PiDiamondsFourFill size={20} className="mr-2" />
          <p>
            To conduct the qualified research works which are contributive to
            subject areas, regions, and country
          </p>
        </Flex>
        <Flex justify="start" direction="row">
          <PiDiamondsFourFill size={20} className="mr-2" />
          <p>To create a continuous learning academic community</p>
        </Flex>
      </Flex>
    </>
  );
};

export default HonoursDegree;
