import { RiGraduationCapFill } from "react-icons/ri";
import { Flex, Image, Text } from "../ui";
import { PiPhoneCallFill } from "react-icons/pi";

function ContactInfoCard({ image, program, phoneNumber }) {
  return (
    <>
      <Flex
        direction="col"
        justify="start"
        className="rounded-t-lg m-5 mobile-scale-08 border-t-8 max-w-[371px] border-t-yellow-400">
        <Image src={image} alt={program} width="371px" height="148px" />
        <div
          style={{ backgroundColor: "rgba(41, 74, 112, 1)" }}
          className="w-full p-3 pt-6 pb-6">
          <div className="flex flex-row gap-4">
            <RiGraduationCapFill size={25} color="white" />
            <Text
              content={program}
              color="white"
              size="18px"
              backgroundColor="transparent"
            />
          </div>
          <div className="flex flex-row gap-4">
            <PiPhoneCallFill size={25} color="white" />
            <Text
              content={phoneNumber}
              color="white"
              size="18px"
              weight="400"
              backgroundColor="transparent"
            />
          </div>
        </div>
      </Flex>
    </>
  );
}

export default ContactInfoCard;
