import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import CommerceLogo from "../../assets/departmentLogo/Commerce.jpg";
import {
  commerceActiBOne,
  commerceActiBTwo,
  commerceActiBThree,
  commerceActiAOne,
  commerceActiCOne,
  commerceActiDOne,
} from "../../assets";

const CommerceHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);
  const images = [
    commerceActiBOne,
    commerceActiBTwo,
    commerceActiBThree,
    commerceActiAOne,
    commerceActiCOne,
    commerceActiDOne,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  const objectivesDetail = [
    "To improve the area of expertise keeping with international standards",
    "To offer professional courses fulfilling the requirements of current era",
    "To accelerate innovative research contributing to the respective industry",
    "To collaborate with international universities leading to knowledge exchange",
    "To enrich ethical and socially responsible professionals for the country",
  ];
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 mobile-center mobile-column">
        <Flex
          direction="col"
          justify="center"
          align="center"
          className="text-justify mr-5">
          <HeadingCard
            title="Department of Commerce"
            departmentLogo={CommerceLogo}
          />
          <Text
            content="   The Department of Commerce is one of the major departments of the Monywa University of Economics which has been granted semi-autonomy by the Ministry of Education. The main objectives of the department are to make contributions to the teaching efforts of the University, and to carry out professional activities and teaching the subjects related to accounting, banking and finance, marketing management, human resources management to undergraduate programs, postgraduate diploma programs, master and PhD programs."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <div className="flex justify-start items-start w-full">
            <Text
              content="Objectives"
              color="black"
              size="18px"
              weight="600"
              className="text-left"
            />
          </div>
          <ol style={{ color: "rgba(51, 51, 51, 0.8)" }} className="ml-5">
            <li className="flex text-left pl-3 flex-row justify-start items-start">
              <p className="mr-2">1.</p>
              To provide the students with a wide range of managerial skills and
              help them deepen their understanding in the area of accounting,
              banking and finance, and marketing management
            </li>
            <li className="flex text-left pl-3 flex-row justify-start items-start">
              <p className="mr-2">2.</p>
              To nurture scholars who possess a fundamental research capability
              and business professionals who have expertise sufficient to
              independently engage in business activities by providing the
              students with advanced knowledge and expertise related to
              commercial science.
            </li>
          </ol>
          <Text
            content="Master of Commerce (MCom) and Master of Accounting (MAct) are postgraduate courses for students who have completed their Bachelors in Commerce and Accounting . The main mission of these programs are to offer quality education in the field of commerce and accounting at a higher level and to upgrade the bachelor students to understanding about the professional world. The duration of these degrees span a period of two-year with three semesters for course work and one semester for thesis."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="For each specialization for a bachelor’s degree, the Department of Commerce has designed a four-year program. For the award of the Bachelor of Commerce (BCom) and the Bachelor of Accounting (BAct), a student must complete 48 courses structured with lecturer, assignments, presentations, mid-terms test and final examinations."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />

          <Text
            content="Master of Commerce (MCom) and Master of Accounting (MAct) are postgraduate courses for students who have completed their Bachelors in Commerce and Accounting . The main mission of these programs are to offer quality education in the field of commerce and accounting at a higher level and to upgrade the bachelor students to understanding about the professional world. The duration of these degrees span a period of two-year with three semesters for course work and one semester for thesis."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="Doctor of Philosophy in Commerce (PhD) is the highest academic title awarded by university. This program aims to develop scholars for higher level careers in research and teaching, practice, consulting, training and development and new business creation. Studying for a PhD is a rewarding experiences. It is an opportunity to become an expert in chosen topic and to make both an academic and practical contribution to knowledge. Students can specialize in one of the areas: commerce, human resource management, entrepreneurship, marketing, finance and or any other allied areas of interest in Management and Commerce. PhD in commerce is a five year doctorate level course in Commerce and Business Management in which every students has to take one year for coursework and need to take four years for preparing thesis."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center mt-5  md:justify-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="We are committed to secure the quality of education through the involvement of faculty, industry, and society encountering global issues altogether."
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesDetail}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default CommerceHeading;
