import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import classroom from "../../assets/departments/Mathematics/clsroom.jpg";
import ManagementLogo from "../../assets/departmentLogo/mathLogo.jpg";

const MathematicsDepartmentHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const images = [classroom];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const objectivesData = [
    "To share mathematical knowledge, abilities and interests needed to students from other major ",
    "To develop and support mathematical identity for each of the student",
    "To develop mathematical curiosity and use inductive and deductive reasoning when solving the problems  ",
  ];

  const visionData = [
    "To develop problem-solving and critical thinking skills necessary to compete in global society",
    "To promote in general the role of mathematics in another fields ",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="start"
          className="text-justify ">
          <HeadingCard
            title="Department of Mathematics"
            departmentLogo={ManagementLogo}
          />
          <Text
            content="In Monywa University of Economics, Department of Mathematics is a supporting department and supports the courses: Business Mathematics, Applied Mathematics, Pure Mathematics and Managerial Mathematics to undergraduate and postgraduate students from other major subjects."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="	Myanmar subject and Aspects of Myanmar are taught to all 1st year economics students."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detailGroup={visionData}
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="To produce resource persons who can analyze and solve the problems effectively in many different jobs faced in their real-life situations"
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesData}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default MathematicsDepartmentHeading;
