import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import {
  geoLogo,
  geoActi1,
  geoActi3,
  geoAct2,
  geoAct4,
  geoAct5,
  geoAct6,
} from "../../assets";

const GeographyDepartmentHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const images = [geoActi1, geoActi3, geoAct2, geoAct4, geoAct5, geoAct6];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const objectivesData = [
    "To disseminate geographic knowledge to the wider public",
    "To effectively assist national development with geographic knowledge ",
    "To contribute to improving the quality of education, training and capacity    enhancement",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 p-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="start"
          className="text-justify ">
          <HeadingCard
            title="Department of Geography"
            departmentLogo={geoLogo}
          />
          <Text
            content="The Department of Geography is one of the four supporting departments in the Monywa University of Economics. It supports the teaching of Geography for all first year candidates with new economic geography and geography of Myanmar for second year trade and logistics students.
"
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="To support region-based development processes and to deepen the opportunities which emerge from local natural and human resources in growing connection with international economic opportunities"
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="To encourage and support high level research that enhances understanding of the different phenomena and processes in the States and Regions of Myanmar"
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesData}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default GeographyDepartmentHeading;
