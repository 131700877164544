import React from "react";
import CommerceSectionsTemplate from "../../components/commerce/CommerceSectionsTemplate";
import {
  khinMyoAye,
  myaWuttYee,
  shuneLaeHmue,
  thetWaiZin,
  aydc,
  aydc1,
  aydc2,
  aydc3,
  ecse,
  ecse1,
  itc,
  itc1,
  sabbath,
  sabbath1,
} from "../../assets";
import EnglishDepartmentHeading from "../../components/sup-departments-headings/EnglishDepartmentHeading";

const English = () => {
  const curriculums = [
    {
      title: "Semester I",
      degrees: [
        "B.Eng - 1001 Business English",
        "B.Eng - 2001 Business English",
        "B.Eng - 2003 English for Logistics",
        "B.Eng - 3003 Professional English for Students of Logistics",
      ],
    },
    {
      title: "Semester II",
      degrees: [
        "B.Eng - 1002 Business English",
        "B.Eng - 2002 Business English",
        "B.Eng - 2004 English for Logistics",
        "B.Eng - 3002 Business English",
        "B.Eng - 3004 English for Trade Fairs & Events",
      ],
    },
  ];

  const headInfo = {
    name: "Daw Khin Myo Aye",
    profileImage: khinMyoAye,
    department: "Department of English",
    position: "Associate Professor & Head Of Department",
  };
  const members = [
    [
      {
        name: "Daw Khin Myo Aye",
        position: "Associate Professor",
        profile: khinMyoAye,
      },
    ],
    [
      {
        name: "Daw Shume Lae Hmue",
        position: "Tutor",
        profile: shuneLaeHmue,
      },
      {
        name: "Daw Thet Wai Wai Zin",
        position: "Tutor",
        profile: thetWaiZin,
      },
      {
        name: "Daw Mya Wutt Yee",
        position: "Tutor",
        profile: myaWuttYee,
      },
    ],
  ];
  const degrees = ["No Data"];
  const programs = null;
  const facultyResearch = [
    {
      name: "No Data",

      research: [],
    },
  ];

  const activities = [
    {
      title: "2023-2024 Academic Year Departmental Training Course",
      image: aydc,
    },
    {
      title: "2023-2024 Academic Year Departmental Training Course",
      image: aydc1,
    },
    {
      title: "2023-2024 Academic Year Departmental Training Course",
      image: aydc2,
    },
    {
      title: "2023-2024 Academic Year Departmental Training Course",
      image: aydc3,
    },
    {
      title: "2022-2023 Academic Year English Class for Scholarship Exam",
      image: ecse,
    },
    {
      title: "2022-2023 Academic Year English Class for Scholarship Exam",
      image: ecse1,
    },
    {
      title: "2022-2023 Academic Year Impromptu Talk Competition",
      image: itc,
    },
    {
      title: "2022-2023 Academic Year Impromptu Talk Competition",
      image: itc1,
    },
    {
      title:
        "2022-2023 Academic Year Traditional Religious Ceremony (Sabbath Ceremony) Department of English",
      image: sabbath,
    },
    {
      title:
        "2022-2023 Academic Year Traditional Religious Ceremony (Sabbath Ceremony) Department of English",
      image: sabbath1,
    },
  ];

  return (
    <div className="ml-32 mr-12 mobile-margin-x-zero">
      <EnglishDepartmentHeading />
      <CommerceSectionsTemplate
        curriculumsTypes={curriculums}
        headMaster={headInfo}
        groupMembers={members}
        researches={facultyResearch}
        news={activities}
        offeredDegrees={degrees}
        hrdProgrammes={programs}
      />
    </div>
  );
};

export default English;
