import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import {
  mbaActi1,
  mbaActi2,
  mbaActi4,
  mbaActi5,
  mbaActi6,
  mbaActi7,
  mbaActi8,
  mbaActi9,
} from "../../assets";
import ManagementLogo from "../../assets/departmentLogo/Mgt.jpg";

const ManagementStudiesHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const images = [
    mbaActi1,
    mbaActi2,
    mbaActi4,
    mbaActi5,
    mbaActi6,
    mbaActi7,
    mbaActi8,
    mbaActi9,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="start"
          className="text-justify ">
          <HeadingCard
            title="Department of Management Studies"
            departmentLogo={ManagementLogo}
          />
          <Text
            content=" Department of Management Studies has been established in Monywa University of Economics since 1998. Department of Management Studies aims to nurture business and management professionals who can give the strategic leadership in today’s challenging business environment. In accordance with it, DMS offers both graduate degrees (BBA and BBA (Honours)) and master degree (MBA) specialized in business administration. DMS has produced graduates who are currently serving for reputable organizations both in public and private sector. DMS is continuously endeavoring to generate responsible leaders, managers, entrepreneurs, and professionals who can make a positive contribution to the society and environment."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content=" 1. To demonstrate proficiency in core business disciplines. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />
          <Text
            content="  2. To apply theoretical concepts and techniques for real world scenarios. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />
          <Text
            content="   3. To nurture effective communication abilities essential for
            collaborating with diverse teams and stakeholders."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />

          <Text
            content="The learning objectives of Master of Business Administration (MBA) are developed as follows:"
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content=" 1. To develop leadership skills to effectively lead teams, manage conflicts, and motivate individuals toward common organizational goals. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />
          <Text
            content=" 2.  To understand and apply ethical principles in business decision making."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />
          <Text
            content="  To develop an entrepreneurial mindset and to foster innovation within organizations.
"
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 ml-2"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default ManagementStudiesHeading;
