import React from "react";
import CommerceSectionsTemplate from "../../components/commerce/CommerceSectionsTemplate";
import {
  zinMarAye,
  naingNaingMyint,
  zinMarWin,
  choChoHtay,
  mmActi1,
  mmActi2,
  mmActi3,
  mmActi4,
  mmActi5,
  mmActi6,
  mmActi7,
  mmActi8,
  mmActi9,
  mmActi10,
  mmActi11,
  mmActi12,
  yeeYeeCho,
} from "../../assets";
import MyanmarDepartmentHeading from "../../components/sup-departments-headings/MyanmarDepartmentHeading";

const Myanmar = () => {
  const curriculums = [
    {
      degrees: ["Myanmar"],
    },
  ];

  const headInfo = {
    name: "Dr Yee Yee Cho",
    profileImage: yeeYeeCho,
    department: "Department of Myanmar",
    position: "Professor & Head Of Department",
  };
  const members = [
    [
      {
        name: "Dr Yee Yee Cho",
        position: "Professor",
        profile: yeeYeeCho,
      },
    ],
    [
      {
        name: "Dr. Zin Mar Aye",
        position: "Associate Professor",
        profile: zinMarAye,
      },
    ],
    [
      {
        name: "Daw Naing Naing Myint",
        position: "Lecturer",
        profile: naingNaingMyint,
      },
    ],
    [
      { name: "Daw Zin Mar Win", position: "Tutor", profile: zinMarWin },
      { name: "Daw Cho Cho Htay", position: "Tutor", profile: choChoHtay },
    ],
  ];
  const degrees = ["No Data"];
  const programs = null;
  const facultyResearch = [
    {
      name: "Dr. Su Myat Aye, Assistant Professor (Head)",
      research: [
        "Influencing Factors on Choosing Fertilizer Brands in Chaung Oo Area, Monywa University of Economics Research Journal, 2014, December, Vol-5, No-1, pp. 123-133",
        "Competitive Strategies and Firms' Performance of Soft Drink Industry in Myanmar, Universities' Research Journal, 2018, August, Vol-10, No-10, pp. 239-258",
        "Consumers' Attitudes Towards Marketing Mix of Petrol Stations on Monywa-Mandalay High-Way Road, Monywa University of Economics Research Journal, 2023, March, Vol-13, No-1, pp. 288-302",
      ],
    },
    {
      name: "Dr. Zin Mar New, Lecturer",
      research: [
        "Customer Relationship Management Practices of Private Banks in Monywa, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, March, Vol-14, No-1, pp. 182-197",
      ],
    },
    {
      name: "U Aung Chan Aye, Lecturer",
      research: [
        "The Influence of Corporate Social Responsibility (CSR) and Service Quality on Customer Loyalty Outcomes: The Private Banking Case in Myanmar, TNI Journal of Business Administration and Languages, 2019, Vol-7, No-2, pp. 61-75",
        "The Mediating Role of Perceived Value on the Relationship between Service Quality and Loyalty: The Private Banking Case in Myanmar, TNI Journal of Business Administration and Languages, 2020, Vol-8, No-1, pp. 88-100",
        "The Mediating Role of Perceived Value and Customer Satisfaction on the Relationship between Service Convenience and Loyalty: A Case Study of Private Bank in Myanmar, Human Behavior Development and Society, 2021, Vol-22, No-2, pp. 60-71",
        "Exploring the Effects of Corporate Social Responsibility on Loyalty of KBZ Bank Users, Monywa University of Economics Peer Reviewed Journal, 2022, Vol-12, pp. 139-153",
        "The Mediating Role of Job Satisfaction on the Relationship between Knowledge Management Practices and Employee Work Performance: The Private Banking Case in Myanmar, TNI Journal of Business Administration and Languages, 2020, Vol-8, No-2, pp. 83-97",
        "Leadership as Merit Making: The Case of Myanmar and Applications for Western Organizations, Innovative Leadership in Times of Compelling Changes, 2021, pp. 219-231",
        "Influencing Factor of Knowledge Sharing on Knowledge Workers Performance: Case Study in University of Cooperative and Management, The Myanmar Journal, 2022, Vol-9, No-2, pp. 279-296",
      ],
    },
    {
      name: "Daw Shwe Sin Win, Assistant Lecturer",
      research: [
        "Employee Motivation, Job Satisfaction and Intention to Stay at selected Hotels in Monywa, Monywa University of Economics Research Journal, 2019, August, Vol-10, No-1, pp. 19-34",
        "Followership Styles and Job Performance of Teachers in Monywa University of Economics, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, March, Vol-14, No-2, pp. 215-229",
      ],
    },
    {
      name: "Daw Nyo Hnin Htwe, Tutor",
      research: [
        "Customer Relationship Management Practices and Customer Satisfaction of Telecom International Myanmar Co., Ltd (Mytel Wi-Fi), Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, March, Vol-14, No-1, pp. 212-225",
      ],
    },
    {
      name: "Daw May Myat Mon, Tutor",
      research: [
        "The Effect of Consumers Perceived Risk and Repurchase Intention of Online Shopping, Monywa University of Economics Research Journal, 2023, August, Vol-13, No-1, pp. 303-317",
      ],
    },
    {
      name: "Daw Moe Moe Nyein, Tutor",
      research: [
        "Training Practices and Employee Performance of Coca-Cola Myanmar, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, March, Vol-14, No-2, pp. 95-111",
        "Career Development and Commitment of Teachers at Selected Universities in Monywa Township, Shwe Bo University of Research Journal, 2022, March, Vol-12, pp. 102-111",
      ],
    },
    {
      name: "Daw Nyein Thiri Zaw, Tutor",
      research: [
        "Career Development and Commitment of Teachers at Selected Universities in Monywa Township, Shwe Bo University of Research Journal, 2022, March, Vol-12, pp. 102-111",
        "The Effect of Consumers Perceived Risk and Repurchase Intention of Online Shopping, Monywa University of Economics Research Journal, 2023, August, Vol-13, No-1, pp. 303-317",
      ],
    },
    {
      name: "Daw Ei Ei Theint, Tutor",
      research: [
        "Service Marketing Mix and Customer Loyalty of Monywa Ocean Supercenter, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, March, Vol-14, No-1, pp. 240-254",
      ],
    },
  ];

  const activities = [
    { title: "Graduation Ceremony for Office Work", image: mmActi1 },
    { title: "Computer Training", image: mmActi2 },
    { title: "Refresher Course", image: mmActi3 },
    { title: "Discussion", image: mmActi4 },
    { title: "Refresher Course", image: mmActi5 },
    { title: "Youth Conversation", image: mmActi6 },
    { title: "Lecture on Martyr's Day", image: mmActi7 },
    { title: "Refresher Course", image: mmActi8 },
    { title: "Tree Planting Ceremony", image: mmActi9 },
    {
      title: "Sabbath ceremony",
      image: mmActi10,
    },
    {
      title: "Sabbath ceremony",
      image: mmActi11,
    },
    {
      title: "Sabbath ceremony",
      image: mmActi12,
    },
  ];

  return (
    <>
      <div className="ml-32 mr-12 mobile-margin-x-zero">
        <MyanmarDepartmentHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default Myanmar;
