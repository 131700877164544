import React from 'react'
import { DataRequire } from '../../components'

const ICTDepartment = () => {
  return (
    <div>
      <DataRequire/>
    </div>
  )
}

export default ICTDepartment