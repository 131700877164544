import { Flex } from "../ui";
function FacultyCard({ name, position, profile }) {
  return (
    <Flex
      direction="col"
      justify="center"
      items="center"
      className="m-5 p-4 -z-10 mobile-scale-08 rounded-3xl border-2 border-t-8 border-t-yellow-400 min-w-[300px] max-w-[308px] min-h-[238px] max-h-[240px] border-blue-950">
      <img
        src={profile}
        alt={`Profile of ${name}`}
        style={{
          objectPosition: "top",
          width: "145px",
          height: "151px",
          objectFit: "cover", // Ensures the image covers the area
        }}
      />
      <p className="text-center mobile-scale-08 font-medium text-lg mt-3">
        {name}
      </p>

      <p className="mobile-scale-08 whitespace-nowrap">{position}</p>
      {/* <p className="mobile-scale-08">{degree}</p> */}
    </Flex>
  );
}
export default FacultyCard;
