import React from "react";
import { Flex, Text } from "../ui";
import dinOrdea from "../assets/IRO/dinOrdea.png";
import maniPurlUni from "../assets/IRO/manipurUni.png";

export default function MouMoa() {
  const bonds = [
    {
      title:
        "Lorem ipsum dolor sit amet consectetur. Posuere cras purus mauris in venenatis.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cras purus mauris in venenatis. Euismod donec convallis gravida adipiscing est at viverra nunc volutpat. Viverra tortor eu sit fringilla pharetra malesuada eget augue. Pellentesque risus massa potenti lacus. Pharetra ipsum in massa purus nibh.",
      image: dinOrdea,
      isReverse: false,
    },
    {
      title:
        "Lorem ipsum dolor sit amet consectetur. Posuere cras purus mauris in venenatis.",
      description:
        "Lorem ipsum dolor sit amet consectetur. Posuere cras purus mauris in venenatis. Euismod donec convallis gravida adipiscing est at viverra nunc volutpat. Viverra tortor eu sit fringilla pharetra malesuada eget augue. Pellentesque risus massa potenti lacus. Pharetra ipsum in massa purus nibh.",
      image: maniPurlUni,
      isReverse: true,
    },
  ];
  return (
    <Flex direction="col" justify="start" className="p-10">
      {bonds.map((bond, index) => (
        <>
          <div
            className={`flex items-center justify-center flex-col ${
              bond.isReverse ? "md:flex-row-reverse" : "md:flex-row"
            } `}
            key={index}>
            <img src={bond.image} className="h-80 w-80" alt={bond.title} />
            <Flex
              direction="col"
              justify="start"
              align="start"
              className="p-5 border-t-8 mt-5 rounded-3xl max-w-[800px] border-t-yellow-300 h-auto  shadow-md shadow-[#036] border-2 border-[#036]">
              <Text
                content={bond.title}
                size="18px"
                weight="500"
                className="mb-4"
              />
              <Text
                content={bond.description}
                size="16px"
                weight="400"
                className="mb-4"
              />
            </Flex>
          </div>
        </>
      ))}
    </Flex>
  );
}
