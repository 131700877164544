import { AboutBottom } from "../components";
import AboutHeader from "../components/about/AboutHeader";

function About() {
  return (
    <>
      <div style={{ padding: "10px 7vw" }}>
        <AboutHeader />
        <AboutBottom />
      </div>
    </>
  );
}

export default About;
