import React from "react";
import { Flex, Text } from "../../ui";
import { PiDiamondsFourFill } from "react-icons/pi";

const DoctoralDegrees = () => {
  return (
    <>
      <Flex
        direction="col"
        justify="start"
        items="center"
        className="p-8 max-w-[1200px] rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950">
        <Text
          color="black"
          size="16px"
          weight="400"
          content="Yangon University of Economics has been conducting PhD programme since 1998. YUE has nurtured 89 PhD graduates and ( 68 ) candidates are attending their PhD courses. The requirements for PhD admissions are as follows:"
          className="mb-7 text-justify pr-72"
        />
        <div className="flex justify-start items-start w-full text-left flex-row">
          <PiDiamondsFourFill size={20} className="mr-5" />
          <Text
            color="black"
            size="18px"
            weight="400"
            content="The age must be below 30 years. If he or she has working experience, the age limitation might be waived."
          />
        </div>
        <div className="flex text-left flex-row justify-start items-start w-full">
          <PiDiamondsFourFill size={20} className="mr-5" />
          <Text
            color="black"
            size="18px"
            weight="400"
            className="mb-5"
            content="1 year full-time participation is required in PhD preliminary course"
          />
        </div>
        <Flex
          direction="row"
          justify="center"
          items="center"
          className="p-8 text-justify rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950">
          <Flex direction="row" justify="start">
            <Flex direction="col" justify="start" align="start">
              <Text
                weight="500"
                size="20px"
                content="For teaching/administrative staff from Ministries"
                color="black"
              />
              <div
                className="h-1 mt-3 w-16 mb-3  hover:w-40 hover:duration-1000"
                style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
              <ol
                style={{ color: "#333", fontSize: "14px", fontWeight: "400" }}>
                <li className="flex justify-start items-start flex-row gap-3">
                  <p className="text-base">1</p>
                  <p className="text-base">
                    A master degree from any recognized university majoring in
                    applied field of study.
                  </p>
                </li>
                <li className="flex flex-row justify-start items-start gap-3">
                  <p className="text-base">2</p>
                  <p className="text-base">
                    A pass in entrance written examination and satisfactory
                    interview results are required.
                  </p>
                </li>
                <li className="flex flex-row justify-start items-start gap-3">
                  <p className="text-base">3</p>
                  <p className="text-base">
                    A Master of Research (MRes) degree holder is required to sit
                    for interview examination only.
                  </p>
                </li>
                <li className="flex flex-row justify-start items-start gap-3">
                  <p className="text-base">4</p>
                  <p className="text-base">
                    If the candidate is a tutor/demonstrator of universities or
                    degree colleges, the working experience must have one year
                    at a minimum.
                  </p>
                </li>
                <li className="flex flex-row justify-start items-start gap-3">
                  <p className="text-base">5</p>
                  <p className="text-base">
                    If the candidate is an administrative staff, a minimum 2
                    years of working experience and research works are required.
                    In addition, the recommendation of his or her Department
                    Head is required.
                  </p>
                </li>
                <li className="flex flex-row justify-start items-start gap-3">
                  <p className="text-base">6</p>
                  <p className="text-base">
                    The one year study leave for PhD preliminary course from
                    Director General or Rector/Principal concerned.
                  </p>
                </li>
              </ol>
            </Flex>
            <div
              className="w-1 min-h-96 m-3 h-full"
              style={{ backgroundColor: "rgba(0, 51, 102, 0.5)" }}></div>
            <Flex direction="col" align="start" justify="start">
              <Text
                weight="500"
                size="20px"
                content="For non-government candidate"
                color="black"
              />
              <div
                className="h-1 w-16 mb-3 mt-3 hover:w-40 hover:duration-1000"
                style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>

              <div className="flex flex-row gap-5">
                <PiDiamondsFourFill size={50} />
                <p className="mb-5">
                  A Master of Research (MRes) degree from any recognized
                  university majoring in applied field of study. Synopsis of
                  MRes and the thesis proposal for PhD (no more than 3 pages)
                  are required. A satisfactory interview result is required.
                </p>
              </div>
              <Text
                content="A Master of Research (MRes) degree from any recognized university majoring in applied field of study. Synopsis of MRes and the thesis proposal for PhD (no more than 3 pages) are required. A satisfactory interview result is required."
                color="black"
                size="16px"
                backgroundColor="transparent"
                className="mb-2"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default DoctoralDegrees;
