import React from "react";
import { Flex, Text } from "../ui";

const ActivitiesPostCard = ({ title, description, images }) => {
  return (
    <>
      <div className="pb-9 mb-5 flex justify-start flex-wrap items-start w-full flex-col border-b-2 border-dotted">
        <Text
          content={title}
          color="#333"
          weight="400"
          size="28px"
          className="text-left max-w-[100vw] text-wrap pr-5 h-auto"
        />
        <Text
          content={description}
          color="rgba(51, 51, 51, 0.8)"
          weight="400"
          size="20px"
          className="mt-5 text-left max-w-[100vw] text-wrap  mb-5"
        />
        <Flex
          direction="row"
          align="center"
          justify="start"
          className="gap-5  overflow-x-visible flex flex-row">
          {images.map((image, index) => (
            <div
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                minWidth: "370px",
                minHeight: "355px",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",

                overflowX: "auto",
              }}>
              <Text
                content={`0${index + 1}`}
                backgroundColor="rgba(255, 215, 0, 0.5)"
                color="rgba(255, 255, 255, 1)"
                className="pr-5 pl-5 pt-1 pb-1"
              />
            </div>
          ))}
        </Flex>
      </div>
    </>
  );
};

export default ActivitiesPostCard;
