import React, { useState, useEffect } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import engLogo from "../../assets/departments/English/image.png";
import classroom from "../../assets/departments/English/classroom.jpg";
import building from "../../assets/departments/English/building.jpg";

const EnglishDepartmentHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);

  const images = [classroom, building];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const objectivesData = [
    "To expand the vocabulary related to the respective fields through reading",
    "To understand the different types of writing and practice in writing; such as summary, email, reports, memos, meeting minutes, job vacancy advertisements, application letters, complaint letter, etc.",
    "To improve students’ listening skills practicing audio scripts",
    "To develop students’ English communication skills through interactive and motivating",
  ];
  const missionsData = [
    "To help students improve their proficiency in English language",
    "To support the students to be qualified in their respective academic disciplines",
    "To develop students’ effective communication skills in their academic and professional careers",
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="start"
          className="text-justify ">
          <HeadingCard title="Department of English" departmentLogo={engLogo} />
          <Text
            content="The Department of English is one of the Supporting Departments at Monywa University of Economics. Currently, it runs with a total teaching staff of four: one associate professor and three tutors."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="Since the Department of English is a supporting department, it offers courses on Business English to students specializing in respective academic disciplines such as Economics, Statistics, Commerce, Accounting, Business Administration, and Trade and Logistics for first-year students. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="	In the Myanmar subject includes the prose, poetry and language. In addition, the students can learn how to write office letters in their real lives."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="Business English is continuously delivered for Economics, Commerce, Accounting, Statistics, and Business Administration specialization students in the second year, and Trade and Logistics students are introduced to the new course, English for Logistics in the second year. "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
          <Text
            content="Business English is being taught to third-year and first year honours students specializing in Economics and Statistics. For third-year and first year honours Trade and Logistics students, Professional English for Students of Logistics is prescribed for Semester I, and English for Trade Fairs and Events is intended for Semester II. English Department provides the entrance examination for MBA, MPA, MMM, MAS, MBF, and MDevS programmes. It also supports the teaching of Business English for DBS and Dip.DS in the first quarter.  "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={images[currentImageIndex]}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="To enhance students’ proficiency in English for their academic and professional success"
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detailGroup={missionsData}
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesData}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default EnglishDepartmentHeading;
