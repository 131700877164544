import React from "react";
import { Text, Flex, Image } from "../../ui";
import { siteLogo } from "../../assets";

const AdmissionHeader = () => {
  return (
    <>
      <Text
        content="Admission Requirements"
        size="30px"
        weight="400"
        className="text-left"
      />
      <div
        className="h-1 mb-3 w-16  hover:w-40 hover:duration-1000"
        style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
      <Flex
        direction="row"
        justify="evenly"
        items="start"
        className="p-8 mt-10 rounded-3xl border-2 border-t-8 border-t-yellow-400 border-blue-950">
        <div className="w-1/3">
          <Image src={siteLogo} width="85px" height="93px" alt="Logo" />
        </div>
        <Flex
          direction="col"
          justify="between"
          align="start"
          className="h-full">
          <Text
            color="#333"
            content="OUR CORE VALUES"
            size="20px"
            className="mb-3"
          />
          <div
            className="h-1 w-16 mb-3  hover:w-40 hover:duration-1000"
            style={{ backgroundColor: "rgba(51, 51, 51, 1)" }}></div>
          <Text
            color="#333"
            content="Lorem ipsum dolor sit amet consectetur. Egestas arcu consequat dignissim odio sed sed faucibus pharetra diam."
            size="18px"
            className="mb-3 text-justify"
          />
        </Flex>
        <div
          className="w-1 h-full min-h-80 m-5"
          style={{
            backgroundColor: "rgba(0, 51, 102, 0.50)",
          }}></div>
        <Flex direction="col" justify="between" align="start">
          <Text
            color="#333"
            content="Characteristics (Strengths and Unique Points)"
            size="20px"
            className="mb-3"
          />
          <div
            className="h-1 mb-3 w-16  hover:w-40 hover:duration-1000"
            style={{ backgroundColor: "rgba(51, 51, 51, 1)" }}></div>
          <Text
            color="#333"
            content="Lorem ipsum dolor sit amet consectetur. At morbi lectus ipsum posuere placerat urna elementum adipiscing. Est amet commodo eu facilisis ante faucibus dignissim. Iaculis arcu mi nunc elementum mattis ullamcorper faucibus vel habitant. Quis tortor nunc sodales laoreet suspendisse neque."
            size="18px"
            className="mb-3 text-justify"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default AdmissionHeader;
