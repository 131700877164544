import React from "react";
import { useState, useEffect } from "react";
import { unionOne, unionThree, unionTwo } from "../../assets";

function HeroHeader() {
  const images = [unionOne, unionThree, unionTwo];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div
        style={{
          height: "415px",
          minWidth: "100vw",
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}></div>
    </>
  );
}

export default HeroHeader;
