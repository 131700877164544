import React from "react";
import { Flex, Image, Text } from "../../ui";
import { PiDiamondsFourFill } from "react-icons/pi";
import { speakingGuy } from "../../assets";

const ProgrammeDuration = () => {
  return (
    <>
      <div className="flex justify-start items-start">
        <Flex
          direction="col"
          items="start"
          justify="start"
          className="p-8 self-center mt-10 mb-10 max-w-[875px] rounded-3xl border-2 border-l-8 border-l-yellow-400 border-blue-950">
          <div>
            <Text
              content="The duration of degree programme are categorized into as follows:"
              size="20px"
              weight="400"
              className="text-left mb-3"
            />
          </div>
          <Flex direction="row" justify="between" items="center">
            <ul>
              <li className="flex flex-row gap-3">
                <PiDiamondsFourFill size={20} />
                First degree 4 years
              </li>
              <li className="flex flex-row gap-3">
                <PiDiamondsFourFill size={20} />
                Honours degree 5 years
              </li>
              <li className="flex flex-row gap-3">
                <PiDiamondsFourFill size={20} />
                Master degree 2 years
              </li>
              <li className="flex flex-row gap-3">
                <PiDiamondsFourFill size={20} />
                Graduate diploma 2 years
              </li>
              <li className="flex flex-row gap-3">
                <PiDiamondsFourFill size={20} />
                Doctoral degree 5 years
              </li>
            </ul>
            <Image
              src={speakingGuy}
              alt="Speaking"
              width="330px"
              height="175px"
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default ProgrammeDuration;
