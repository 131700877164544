import React from "react";
import { Flex } from "../../ui";
import { FacultyCard } from "../../cards";

const CommerceFacultyMembers = ({ facultyDataGroups }) => {
  return (
    <Flex direction="col" className="h-auto ">
      {facultyDataGroups.map((group, index) => (
        <Flex
          key={index}
          direction="row"
          justify="start"
          align="center"
          className="overflow-x-auto md:flex-wrap md:justify-center">
          {group.map((faculty, idx) => (
            <FacultyCard
              key={idx}
              name={faculty.name}
              position={faculty.position}
              degree={faculty.degree}
              profile={faculty.profile}
            />
          ))}
        </Flex>
      ))}
    </Flex>
  );
};

export default CommerceFacultyMembers;
