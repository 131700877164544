import { FaChevronRight, FaFacebookF, FaSearch } from "react-icons/fa";
import { Flex, Icon, Text, Image } from "../ui";
import { TfiWorld } from "react-icons/tfi";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom"; // Importing Link
import "./index.css";
import UniLogo from "../assets/departmentLogo/School loGo (2).jpg";
import { useState, useRef, useEffect } from "react";
import { IoChevronDownSharp, IoMenu } from "react-icons/io5";
import { siteLogo } from "../assets";
import { useNavigate } from "react-router-dom";
// import Redirects from "./Redirects";

function Navbar() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [subMenuActiveDropdown, setSubMenuActiveDropdown] = useState(null);
  const [departmentMenuActiveDropdown, setDepartmentMenuActiveDropdown] =
    useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const handleSearchInput = () => {
    setIsSearchInputOpen(true);
  };
  const toggleRedirected = () => {
    setMobileMenuOpen(false);
    setSubMenuActiveDropdown(null);
    setDepartmentMenuActiveDropdown(null);
    setActiveDropdown(null);
  };
  const dropdownRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  const Redirects = () => {
    const [activeDropdown, setActiveDropDown] = useState(null);
    const [activeSubDropdown, setActiveSubDropDown] = useState(null);
    const [activeGrandchildrenDropdown, setActiveGrandchildrenDropDown] =
      useState(null);
    const Navigate = useNavigate();
    const items = [
      {
        key: "home",
        label: "Home",
        route: "/",
      },
      {
        key: "about",
        label: "About MUE",
        children: [
          { key: "about", label: "About", route: "/about" },
          { key: "contactUs", label: "Contact Us", route: "/contact-us" },
          {
            key: "organization",
            label: "Organization",
            route: "/Organization",
          },
          { key: "masterPlan", label: "Master Plan", route: "/master-plan" },
          { key: "campus", label: "Campus", route: "/campus" },
          { key: "degreeOffered", label: "Degree Offered", route: "/degree" },
        ],
      },
      {
        key: "prospectus",
        label: "Prospectus",
        route: "/prospectus",
      },
      {
        key: "department",
        label: "Department",
        children: [
          {
            key: "academicDepartment",
            label: "Academic Department",
            grandChildren: [
              {
                key: "mainDepartment",
                label: "Main Department",
                greatGrandChildren: [
                  {
                    key: "commerce",
                    label: "Department of Commerce",
                    route: "/academic-department/main/commerce",
                  },
                  {
                    key: "economics",
                    label: "Department of Economics",
                    route: "/academic-department/main/economics",
                  },
                  {
                    key: "statistics",
                    label: "Department of Statistics",
                    route: "/academic-department/main/statistics",
                  },
                  {
                    key: "managementStudies",
                    label: "Department of Management Studies",
                    route: "/academic-department/main/management",
                  },
                  {
                    key: "appliedEconomics",
                    label: "Department of Applied Economics",
                    route: "/academic-department/main/applied-economics",
                  },
                ],
              },
              {
                key: "supportingDepartment",
                label: "Supporting Department",
                greatGrandChildren: [
                  {
                    key: "myanmar",
                    label: "Department of Myanmar",
                    route: "/academic-department/supporting/myanmar",
                  },
                  {
                    key: "english",
                    label: "Department of English",
                    route: "/academic-department/supporting/english",
                  },
                  {
                    key: "mathematics",
                    label: "Department of Mathematics",
                    route: "/academic-department/supporting/mathematics",
                  },
                  {
                    key: "geography",
                    label: "Department of Geography",
                    route: "/academic-department/supporting/geography",
                  },
                ],
              },
            ],
          },
          {
            key: "administrativeDepartment",
            label: "Administrative Department",
            grandChildren: [
              {
                key: "finance",
                label: "Administration & Finance",
                greatGrandChildren: [
                  {
                    key: "admin",
                    label: "Admin Department",
                    route: "/administrative-department/administration/admin",
                  },
                  {
                    key: "finance",
                    label: "Finance Department",
                    route: "/administrative-department/administration/finance",
                  },
                  {
                    key: "ict",
                    label: "ICT Department",
                    route: "/administrative-department/administration/ict",
                  },
                ],
              },
              {
                key: "academicAffairs",
                label: "Academic Affairs",
                greatGrandChildren: [
                  {
                    key: "studentAffairs",
                    label: "Student Affairs",
                    route:
                      "/administrative-department/academic-affairs/student-affairs",
                  },
                  {
                    key: "sport",
                    label: "Sport",
                    route: "/administrative-department/academic-affairs/sport",
                  },
                  {
                    key: "library",
                    label: "Library",
                    route:
                      "/administrative-department/academic-affairs/library",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        key: "iro",
        label: "IRO",
        children: [
          { key: "moumoa", label: "MOU/MOA", route: "/mou-moa" },
          { key: "project", label: "Project", route: "/project" },
        ],
      },
      {
        key: "research",
        label: "Research",
        children: [
          { key: "maas", label: "MAAS", route: "/research/maas" },
          { key: "urj", label: "URJ", route: "/research/urj" },
          {
            key: "international",
            label: "International",
            route: "/research/international",
          },
          { key: "phdPlan", label: "Ph.D Plan", route: "/research/ph-d" },
          { key: "master", label: "Master", route: "/research/master" },
        ],
      },
      {
        key: "library",
        label: "Library",
        children: [
          { key: "english", label: "English", route: "/library/english" },
          { key: "myanmar", label: "Myanmar", route: "/library/myanmar" },
        ],
      },
      {
        key: "admission",
        label: "Admission",
        // route: "/admission",
        children: [
          {
            key: "bachelorAdmission",
            label: "Bachelor",
            route: "/admission/bachelor",
          },
          {
            key: "masterAdmission",
            label: "Master",
            route: "/admission/master",
          },
        ],
      },
      {
        key: "activities",
        label: "Activities",
        children: [
          { key: "2024", label: "2024", route: "/activities/2024" },
          { key: "2025", label: "2025", route: "/activities/2025" },
        ],
      },
    ];

    return (
      <>
        <div
          className="w-2/3 h-full   p-5 fixed left-0 flex-col flex-wrap z-50  whitespace-normal justify-start items-start"
          style={{ backgroundColor: "rgba(41, 74, 112, 1)" }}>
          {" "}
          <div className="flex flex-row justify-start items-center gap-2 ">
            {" "}
            <Image src={siteLogo} height="50px" alt="site logo" width="auto" />
            <Text
              content="Monywa University of Economics"
              color="rgba(255, 215, 0, 1)"
              size="12px"
              weight="500"
              backgroundColor="transparent"
            />
          </div>
          <Flex
            direction="col"
            justify="start"
            align="start"
            className="max-w-96">
            {items.map((item) => (
              <Flex
                direction="col"
                justify="start"
                align="start"
                className="cursor-pointer"
                key={item.key}>
                <button
                  className="flex flex-row justify-start text-base mt-3 mb-3 w-full items-center"
                  onClick={() => {
                    setActiveDropDown(
                      activeDropdown === item.key ? null : item.key
                    );
                    setActiveSubDropDown(null);
                    setActiveGrandchildrenDropDown(null);
                    item.route && Navigate(item.route);
                    item.route && toggleRedirected();
                  }}>
                  <Text
                    size="14px"
                    color="white"
                    content={item.label}
                    backgroundColor="transparent"
                    weight="400"
                    className="text-white whitespace-nowrap overflow-hidden"
                  />
                  {item.children && (
                    <Icon
                      IconComponent={IoChevronDownSharp}
                      size="14px"
                      color="white"
                      className="ml-2"
                    />
                  )}
                </button>

                {item.children &&
                  item.key === activeDropdown &&
                  item.children.map((dropdown) => (
                    <Flex
                      direction="col"
                      justify="start"
                      align="start"
                      className="cursor-pointer ml-2  shadow−[0px 0px 6px 0px]    text-black"
                      key={dropdown.key}>
                      <button
                        className="flex flex-row p-2 justify-start w-[274px] items-center bg-white h-8"
                        onClick={() => {
                          setActiveSubDropDown(
                            activeSubDropdown === dropdown.key
                              ? null
                              : dropdown.key
                          );
                          setActiveGrandchildrenDropDown(null);

                          dropdown.route && Navigate(dropdown.route);
                          dropdown.route && toggleRedirected();
                        }}
                        style={{
                          backgroundColor: `${
                            activeSubDropdown === dropdown.key
                              ? "rgba(41, 74, 112, 1)"
                              : "white"
                          }`,
                          boxShadow: `${
                            activeSubDropdown === dropdown.key
                              ? "0px 0px 6px 0px"
                              : "0px 2px 4px 0px #60a5fa"
                          }`,
                        }}>
                        <Text
                          size="14px"
                          color={`${
                            activeSubDropdown === dropdown.key
                              ? "white"
                              : "black"
                          }`}
                          content={dropdown.label}
                          weight="400"
                          backgroundColor="transparent"
                          className="text-white whitespace-nowrap overflow-hidden"
                        />
                        {dropdown.grandChildren && (
                          <Icon
                            IconComponent={IoChevronDownSharp}
                            size="14px"
                            color={`${
                              activeSubDropdown === dropdown.key
                                ? "white"
                                : "black"
                            }`}
                            className="ml-2"
                          />
                        )}
                      </button>

                      {dropdown.grandChildren &&
                        dropdown.key === activeSubDropdown &&
                        dropdown.grandChildren.map((subDropDown) => (
                          <Flex
                            direction="col"
                            justify="start"
                            align="start"
                            className="cursor-pointer ml-2    shadow-blue-400  text-black"
                            key={subDropDown.key}>
                            <button
                              className="flex flex-row p-2 justify-start w-[262px] items-center  h-8"
                              style={{
                                backgroundColor: `${
                                  activeGrandchildrenDropdown ===
                                  subDropDown.key
                                    ? "rgba(41, 74, 116, 0.5)"
                                    : "white"
                                }`,
                                boxShadow: `${
                                  activeGrandchildrenDropdown ===
                                  subDropDown.key
                                    ? "0px 0px 6px 0px"
                                    : "0px 2px 4px 0px #60a5fa"
                                }`,
                              }}
                              onClick={() => {
                                setActiveGrandchildrenDropDown(
                                  activeGrandchildrenDropdown ===
                                    subDropDown.key
                                    ? null
                                    : subDropDown.key
                                );
                                subDropDown.route &&
                                  Navigate(subDropDown.route);
                                subDropDown.route && toggleRedirected();
                              }}>
                              <Text
                                size="14px"
                                color={`${
                                  activeGrandchildrenDropdown ===
                                  subDropDown.key
                                    ? "white"
                                    : "black"
                                }`}
                                content={subDropDown.label}
                                weight="400"
                                backgroundColor="transparent"
                                className="text-white whitespace-nowrap overflow-hidden"
                              />
                              {subDropDown.greatGrandChildren && (
                                <Icon
                                  IconComponent={IoChevronDownSharp}
                                  size="14px"
                                  color={`${
                                    subDropDown.key ===
                                    activeGrandchildrenDropdown
                                      ? "white"
                                      : "black"
                                  }`}
                                  className="ml-2"
                                />
                              )}
                            </button>

                            {subDropDown.greatGrandChildren &&
                              subDropDown.key === activeGrandchildrenDropdown &&
                              subDropDown.greatGrandChildren.map(
                                (grandChildDropdown) => (
                                  <button
                                    className="flex flex-row ml-2 p-2 justify-start w-[250px] items-center bg-white h-8"
                                    key={grandChildDropdown.key}
                                    onClick={() => {
                                      Navigate(grandChildDropdown.route);
                                      toggleRedirected();
                                    }}
                                    style={{
                                      boxShadow: "0px 2px 4px 0px #60a5fa",
                                    }}>
                                    <Text
                                      size="14px"
                                      backgroundColor="transparent"
                                      color="black"
                                      content={grandChildDropdown.label}
                                      weight="400"
                                      className="text-white whitespace-nowrap overflow-hidden"
                                    />
                                  </button>
                                )
                              )}
                          </Flex>
                        ))}
                    </Flex>
                  ))}
              </Flex>
            ))}
          </Flex>
        </div>
      </>
    );
  };

  // Function to handle the click outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setMobileMenuOpen(false);
      setIsSearchInputOpen(false);
      setSubMenuActiveDropdown(null);
      setDepartmentMenuActiveDropdown(null);
    }
  };

  // Add event listener for detecting clicks outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to toggle the dropdown
  const toggleDropdown = (dropdownName) => {
    console.log("Dropdown clicked:", dropdownName); // Ensure this is running
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
    console.log("Active dropdown state:", activeDropdown); // Check if this logs
  };

  const toggleSubMenu = (subMenuName) => {
    if (subMenuActiveDropdown === subMenuName) {
      setSubMenuActiveDropdown(null);
    } else {
      setSubMenuActiveDropdown(subMenuName);
    }
  };

  const toggleDepartmentMenu = (departmentMenu) => {
    if (departmentMenuActiveDropdown === departmentMenu) {
      setDepartmentMenuActiveDropdown(null);
    } else {
      setDepartmentMenuActiveDropdown(departmentMenu);
    }
  };

  return (
    <>
      <Flex direction="col" className="fixed z-30">
        {isMobileMenuOpen ? (
          <div ref={dropdownRef}>
            <Redirects />
          </div>
        ) : (
          <Flex
            direction="row"
            justify="between"
            backgroundColor="#294A70"
            className="h-12 min-w-[100vw] mobile-hidden">
            <Flex
              direction="row"
              className="follow-us-section -translate-x-20 lg:scale-90 md:scale-75 relative"
              backgroundColor="rgba(255, 215, 0, 1)">
              <Text
                size="16px"
                color="#333"
                backgroundColor="transparent"
                weight="600"
                content="Follow Us :"
              />
              <Icon
                IconComponent={FaFacebookF}
                size="25px"
                color="black"
                className="ml-10 bg-transparent"
              />
              <Icon
                IconComponent={TfiWorld}
                size="25px"
                color="black"
                className="ml-3 bg-transparent"
              />
            </Flex>

            {/* Contact Section */}
            <Flex
              direction="row"
              justify="end"
              align="center"
              backgroundColor="#294A70"
              className="contact-section  lg:scale-90 md:scale-75 h-full gap-4 pr-36">
              <div className="flex flex-row justify-start items-center">
                <div
                  className="w-16 h-12 flex justify-center items-center bg-white"
                  style={{
                    marginTop: "1px",
                  }}>
                  <Icon
                    IconComponent={FaPhoneVolume}
                    size="25px"
                    color="#294A70" // change color to black or another visible color
                    className="bg-transparent rotate-icon"
                  />
                </div>
                <Text
                  size="14px"
                  color="white"
                  backgroundColor="transparent"
                  content="+959 757560883"
                  className="ml-5"
                />
              </div>
              <div className="flex flex-row justify-start items-center">
                <div
                  className="w-16 h-12 flex justify-center items-center bg-white"
                  style={{
                    marginTop: "1px",
                  }}>
                  <Icon
                    IconComponent={IoIosMail}
                    size="28px"
                    color="#294A70"
                    className="bg-transparent rotate-icon"
                  />
                </div>
                <Text
                  size="14px"
                  color="white"
                  backgroundColor="transparent"
                  content="muerectoroffice@gmail.com"
                  className="ml-5"
                />
              </div>
            </Flex>
          </Flex>
        )}

        <Flex
          direction="row"
          justify="between"
          className="bg-white pt-1 px-4 md:px-32 w-full">
          <Flex direction="row" justify="start" className="gap-3 mobile-row">
            {" "}
            <IoMenu
              size="35px"
              color="rgba(0, 51, 102, 1)"
              className="bg-transparent md:hidden"
              onClick={toggleMobileMenu}
            />
            <Link to="/" onClick={toggleRedirected}>
              {" "}
              <Flex
                direction="row"
                justify="start"
                className="gap-3 mobile-row">
                <Image src={UniLogo} alt="Logo" width="auto" height="74px" />
                <p
                  style={{ color: "#294A70" }}
                  className=" font-medium text-xs md:text-base lg:text-2xl xl:text-3xl  whitespace-nowrap ">
                  Monywa University of Economics
                </p>
              </Flex>
            </Link>
          </Flex>
          <Flex
            backgroundColor={`${
              isSearchInputOpen ? "white" : "rgba(255,215,0,1)"
            }`}
            direction={`${isSearchInputOpen ? "rowReverse" : "row"}`}
            className="max-w-fit rounded-full pl-3 pt-2 pb-2 pr-3 md:max-w-80 md:rounded-3xl border-2 border-yellow-400  md:mr-4 h-10  gap-4">
            <Icon
              size="15px"
              IconComponent={FaSearch}
              onClick={() => {
                setIsSearchInputOpen(true);
              }}
              color={`${isSearchInputOpen ? "black" : "white"}`}
              className="font-extralight"
            />
            <input
              type="text"
              placeholder="Search"
              onClick={handleSearchInput}
              // onChange={handleSearchInput}
              style={{
                borderTopRightRadius: "30px",
                borderBottomRightRadius: "30px",
                marginLeft: "2px",
                marginRight: "-6px",
                border: "none",
              }}
              className={`w-[260px] h-[35px] rounded-r-3xl pl-5${
                isSearchInputOpen ? "" : " mobile-hidden "
              }`}
            />
          </Flex>
        </Flex>

        <div
          ref={dropdownRef}
          className="w-full  mobile-hidden"
          style={{ backgroundColor: "rgba(41,74,112,1)" }}>
          <Flex
            direction="row"
            justify="center"
            align="center"
            className="justify-center lg:max-x-lg pl-48 lg:scale-90 md:scale-75 pr-48 h-11"
            backgroundColor="rgba(41, 74, 112, 1)">
            {/* Adding links for non-chevron routes */}
            <Link
              to="/"
              className="text-white w-full "
              onClick={toggleRedirected}>
              <Text
                size="14px"
                color="white"
                content="Home"
                weight="400"
                backgroundColor="transparent"
                className="text-white nav-heading-item"
              />
            </Link>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="drop-down-title">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("aboutMue")}>
                  <Link to="/about">
                    {" "}
                    <Text
                      size="14px"
                      color="white"
                      content="About MUE"
                      weight="400"
                      backgroundColor="transparent"
                      className="text-white whitespace-nowrap nav-heading-item overflow-hidden"
                    />
                  </Link>
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="ml-2"
                  />
                </button>
              </Flex>

              <div
                className={`drop-down-item ${
                  activeDropdown === "aboutMue" ? "active" : ""
                }`}>
                <Link to="/about" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="About Us"
                    className="drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/contact-us" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Contact Us"
                    className="drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/organization" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Organization"
                    className="drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/master-plan" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Master Plan"
                    className="drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/campus" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Campus"
                    className="drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/degree" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Degree Offered"
                    className="drop-down-content-item rounded-b-2xl relative h-7"
                  />
                </Link>
              </div>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex direction="row" justify="start" align="center">
                <Link
                  to="/prospectus"
                  className="text-white"
                  onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="white"
                    content="Prospectus"
                    weight="400"
                    backgroundColor="transparent"
                    className="text-white whitespace-nowrap overflow-hidden text-ellipsis"
                  />
                </Link>
              </Flex>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              {/* Main Dropdown Trigger */}
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("department")}>
                  <Text
                    size="14px"
                    color="white"
                    content="Department"
                    weight="400"
                    backgroundColor="transparent"
                    className="text-white whitespace-nowrap overflow-hidden text-ellipsis"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="ml-2"
                  />
                </button>
              </Flex>

              {/* Main Dropdown Content */}
              <div
                className={`drop-down-item ${
                  activeDropdown === "department" ? "active" : ""
                }`}>
                {/* Academic Department with Submenu */}
                <div className="department-drop-down-content-item relative rounded-t-lg border-t-0 h-7 ">
                  <button
                    className="flex flex-row items-center justify-between w-full"
                    onClick={() => toggleSubMenu("academicDepartment")}>
                    <Text
                      size="14px"
                      className="menu-text"
                      content="Academic Department"
                      weight="400"
                      backgroundColor="transparent"
                    />
                    <Icon
                      IconComponent={FaChevronRight}
                      size="14px"
                      className="ml-2 menu-icon"
                    />
                  </button>

                  {/* Submenu for Academic Department */}
                  <div
                    className={`sub-menu ml-3 ${
                      subMenuActiveDropdown === "academicDepartment"
                        ? "active"
                        : ""
                    }`}>
                    {/* Main Department with Third-Level Nested Menu */}
                    <div className="department-drop-down-content-item  relative rounded-t-md h-7 ">
                      <button
                        className="flex flex-row items-center justify-between w-full"
                        onClick={() => toggleDepartmentMenu("mainDepartment")}>
                        <Text
                          size="14px"
                          className="menu-text"
                          content="Main Department"
                          weight="400"
                          backgroundColor="transparent"
                        />
                        <Icon
                          IconComponent={FaChevronRight}
                          size="14px"
                          color="black"
                          className="ml-2 menu-icon"
                        />
                      </button>

                      {/* Third Nested Menu for Main Department */}
                      <div
                        style={{ minWidth: "250px" }}
                        className={`sub-menu ml-3 ${
                          departmentMenuActiveDropdown === "mainDepartment"
                            ? "active"
                            : ""
                        }`}>
                        <Link
                          to="/academic-department/main/commerce"
                          onClick={toggleRedirected}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Commerce"
                            backgroundColor="transparent"
                            className="department-sub-menu-content-item w-min-[250px] relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/academic-department/main/economics"
                          onClick={toggleRedirected}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Economics"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/academic-department/main/statistics"
                          onClick={toggleRedirected}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Statistics"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/academic-department/main/management"
                          onClick={toggleRedirected}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Management Studies"
                            className="department-sub-menu-content-item  whitespace-nowrap relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/academic-department/main/applied-economics"
                          onClick={toggleRedirected}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Applied Economics"
                            className="department-sub-menu-content-item whitespace-nowrap relative h-7"
                          />
                        </Link>
                      </div>
                    </div>

                    {/* Supporting Department (Second-Level Submenu) */}
                    <div className="department-drop-down-content-item rounded-b-md relative h-7">
                      <button
                        className="flex flex-row items-center justify-between w-full"
                        onClick={() =>
                          toggleDepartmentMenu("supportingDepartment")
                        }>
                        <Text
                          size="14px"
                          className="menu-text"
                          content="Supporting Department"
                          weight="400"
                          backgroundColor="transparent"
                        />
                        <Icon
                          IconComponent={FaChevronRight}
                          size="14px"
                          color="black"
                          className="ml-2 menu-icon"
                        />
                      </button>
                      <div
                        className={`sub-menu w-fit  ml-3 ${
                          departmentMenuActiveDropdown ===
                          "supportingDepartment"
                            ? "active"
                            : ""
                        }`}>
                        <Link
                          to="/academic-department/supporting/myanmar"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Myanmar"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/academic-department/supporting/english"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of English"
                            backgroundColor="transparent"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>

                        <Link
                          to="/academic-department/supporting/mathematics"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Mathematics"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>

                        <Link
                          to="/academic-department/supporting/geography"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Department of Geography"
                            className="department-sub-menu-content-item relative h-7"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Administrative Department with Submenu */}
                <div className="department-drop-down-content-item rounded-b-lg relative h-7">
                  <button
                    className="flex flex-row items-center justify-between  w-full"
                    onClick={() => toggleSubMenu("administrativeDepartment")}>
                    <Text
                      size="14px"
                      className="whitespace-nowrap menu-text"
                      content="Administrative Department"
                      weight="400"
                      backgroundColor="transparent"
                    />
                    <Icon
                      IconComponent={FaChevronRight}
                      size="14px"
                      color="black"
                      className="ml-2 menu-icon"
                    />
                  </button>

                  {/* Submenu for Administrative Department */}
                  <div
                    className={`sub-menu ml-1 ${
                      subMenuActiveDropdown === "administrativeDepartment"
                        ? "active"
                        : ""
                    }`}>
                    <div className="department-drop-down-content-item relative border-b-2 h-7 border-dotted border-b-gray-500">
                      <button
                        className="flex flex-row items-center justify-between w-full"
                        onClick={() =>
                          toggleDepartmentMenu("administrationFinance")
                        }>
                        <Text
                          size="14px"
                          className="menu-text"
                          content="Administration & Finance"
                          weight="400"
                          backgroundColor="transparent"
                        />
                        <Icon
                          IconComponent={FaChevronRight}
                          size="14px"
                          color="black"
                          className="ml-2 menu-icon"
                        />
                      </button>
                      <div
                        className={`sub-menu ml-1 ${
                          departmentMenuActiveDropdown ===
                          "administrationFinance"
                            ? "active"
                            : ""
                        }`}>
                        <Link
                          to="/administrative-department/administration/admin"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Admin Departments"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/administrative-department/administration/finance"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Finance Departments"
                            backgroundColor="transparent"
                            className="department-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>

                        <Link
                          to="/administrative-department/administration/ict"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="ICT Departments"
                            className="department-sub-menu-content-item relative h-7"
                          />
                        </Link>
                      </div>
                    </div>

                    {/* Nested Submenu for Academic Affairs */}
                    <div className="department-drop-down-content-item relative h-7">
                      <button
                        className="flex flex-row items-center justify-between w-full"
                        onClick={() => toggleDepartmentMenu("academicAffairs")}>
                        <Text
                          size="14px"
                          className="menu-text"
                          content="Academic Affairs"
                          weight="400"
                          backgroundColor="transparent"
                        />
                        <Icon
                          IconComponent={FaChevronRight}
                          size="14px"
                          color="black"
                          className="ml-2 menu-icon"
                        />
                      </button>
                      <div
                        className={`admin-sub-menu ml-1 ${
                          departmentMenuActiveDropdown === "academicAffairs"
                            ? "active"
                            : ""
                        }`}>
                        <Link
                          to="/administrative-department/academic-affairs/student-affairs"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Student Affairs"
                            className="admin-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>
                        <Link
                          to="/administrative-department/academic-affairs/library"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Library"
                            backgroundColor="transparent"
                            className="admin-sub-menu-content-item relative border-b-2 h-7 border-dotted border-b-gray-500"
                          />
                        </Link>

                        <Link
                          to="/administrative-department/academic-affairs/sport"
                          onClick={() => setActiveDropdown(null)}>
                          <Text
                            size="14px"
                            color="black"
                            content="Sport"
                            className="admin-sub-menu-content-item relative h-7"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("iro")}>
                  <Text
                    size="14px"
                    color="white"
                    weight="400"
                    content="IRO"
                    backgroundColor="transparent"
                    className="text-white"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="bg-transparent ml-2"
                  />
                </button>
              </Flex>
              <div
                className={`drop-down-item ${
                  activeDropdown === "iro" ? "active" : ""
                }`}>
                <Link to="/mou-moa" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="MOU/MOA"
                    backgroundColor="transparent"
                    className="drop-down-content-item border-b-2 border-dotted border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/project" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Project"
                    backgroundColor="transparent"
                    className=" drop-down-content-item p-2 h-7"
                  />
                </Link>
              </div>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("research")}>
                  <Text
                    size="14px"
                    color="white"
                    weight="400"
                    content="Research"
                    backgroundColor="transparent"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="bg-transparent ml-2"
                  />
                </button>
              </Flex>
              <div
                className={`drop-down-item ${
                  activeDropdown === "research" ? "active" : ""
                }`}>
                <Link to="/research/maas" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="MAAS"
                    backgroundColor="transparent"
                    className="drop-down-content-item h-7 border-b-2 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/research/urj" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    weight="400"
                    color="black"
                    content="URJ"
                    backgroundColor="transparent"
                    className="border-b-2 h-7 border-dotted drop-down-content-item border-b-gray-500"
                  />
                </Link>
                <Link to="/research/international" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="International"
                    backgroundColor="transparent"
                    className="drop-down-content-item h-7 border-b-2 border-dotted border-b-gray-500"
                  />
                </Link>
                <Link to="/research/ph-d" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    content="Ph.D"
                    weight="400"
                    backgroundColor="transparent"
                    className="border-b-2 h-7 border-dotted drop-down-content-item border-b-gray-500"
                  />
                </Link>
                <Link to="/research/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    weight="400"
                    color="black"
                    content="Master"
                    backgroundColor="transparent"
                    className="drop-down-content-item h-7"
                  />
                </Link>
              </div>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("library")}>
                  <Text
                    size="14px"
                    color="white"
                    content="Library"
                    weight="400"
                    backgroundColor="transparent"
                    className="text-white"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="bg-transparent ml-2"
                  />
                </button>
              </Flex>
              <div
                className={`drop-down-item ${
                  activeDropdown === "library" ? "active" : ""
                }`}>
                <Link to="/library/english" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="English"
                    backgroundColor="transparent"
                    className="h-7 drop-down-content-item "
                  />
                </Link>
                <Link to="/library/myanmar" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Myanmar"
                    backgroundColor="transparent"
                    className="h-7 drop-down-content-item"
                  />
                </Link>
              </div>
            </Flex>
            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("admission")}>
                  <Text
                    size="14px"
                    color="white"
                    content="Admission"
                    weight="400"
                    backgroundColor="transparent"
                    className="text-white"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    weight="400"
                    className="bg-transparent ml-2"
                  />
                </button>
              </Flex>
              <div
                className={`drop-down-item ${
                  activeDropdown === "admission" ? "active" : ""
                }`}>
                <Link to="/admission/bachelor" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Bachelor"
                    backgroundColor="transparent"
                    className="drop-down-content-item border-b-2 border-dotted border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/admission/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Master"
                    backgroundColor="transparent"
                    className="border-b-2 border-dotted drop-down-content-item border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/admission/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Ph.D"
                    backgroundColor="transparent"
                    className="drop-down-content-item border-b-2 border-dotted border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/admission/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="Diploma"
                    backgroundColor="transparent"
                    className="border-b-2 border-dotted drop-down-content-item border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/admission/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    weight="400"
                    color="black"
                    content="Certificate"
                    backgroundColor="transparent"
                    className="drop-down-content-item border-b-2 border-dotted border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/admission/master" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    weight="400"
                    color="black"
                    content="Registration & Payment"
                    backgroundColor="transparent"
                    className="drop-down-content-item h-7"
                  />
                </Link>
              </div>
            </Flex>

            <Flex
              direction="col"
              justify="start"
              align="start"
              className="gap-1 text-left drop-down-title text-white">
              <Flex
                direction="row"
                justify="start"
                align="center"
                className="cursor-pointer">
                <button
                  className="flex flex-row items-center"
                  onClick={() => toggleDropdown("activities")}>
                  <Text
                    size="14px"
                    color="white"
                    weight="400"
                    content="Activities"
                    backgroundColor="transparent"
                    className="text-white"
                  />
                  <Icon
                    IconComponent={IoChevronDownSharp}
                    size="14px"
                    color="white"
                    className="bg-transparent ml-2"
                  />
                </button>
              </Flex>
              <div
                className={`drop-down-item ${
                  activeDropdown === "activities" ? "active" : ""
                }`}>
                <Link to="/activities/2024" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="2024"
                    backgroundColor="transparent"
                    className="drop-down-content-item border-b-2 border-dotted border-b-gray-500 h-7"
                  />
                </Link>
                <Link to="/activities/2025" onClick={toggleRedirected}>
                  <Text
                    size="14px"
                    color="black"
                    weight="400"
                    content="2025"
                    backgroundColor="transparent"
                    className=" drop-down-content-item p-2 h-7"
                  />
                </Link>
              </div>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </>
  );
}

export default Navbar;
