import React from "react";
import CommerceSectionsTemplate from "../../components/commerce/CommerceSectionsTemplate";
import {
  dawAyeAyeMoe,
  dawSanSanLwin,
  dawHtetHtetSanOo,
  dawMohMohWin,
  dawNuNuNwe,
  dawPhyoNyeinThu,
  dawThuzarWin,
  dawYadanarOo,
  dawThuzarMyint,
  dawYeeYeeThant,
  mathActiFour,
  mathActiOne,
  mathActiThree,
  // mathActiTwo,
  dawYinMarOo,
  drKhinPhyuPhyuWin,
  drthantThantSoe,
  uKoKoMyo,
  yaeMonAung,
  mathActiThreeOne,
} from "../../assets";
import MathematicsDepartmentHeading from "../../components/sup-departments-headings/MathematicsDepartmentHeading";

const Mathematics = () => {
  const curriculums = [
    {
      title: "Semester I",
      degrees: [
        "E.Math-1001 Business Mathematics",
        "E.Math-2001 Mathematics",
        "E.Math-2101 Mathematics",
        "E.Math-5201 Mathematics",
        "E.Math-5202 Mathematics",
        "Math-6001(s) Mathematics",
        "Math-6001(b) Mathematics for Economics",
      ],
    },
    {
      title: "Semester II",
      degrees: [
        "E.Math-3101,E.Math-3201 Mathematics",
        "E.Math-4201 Mathematics",
        "Math-6101 Managerial Mathematics",
      ],
    },
  ];

  const headInfo = {
    name: "Dr. Thant Thant Soe",
    department: "Department of Mathematics",
    position: "Professor & Head of Department",
    profileImage: drthantThantSoe,
    passage:
      "Dr. Thant Thant Soe obtained her PhD degree in 2009 from Mandalay University.",
  };

  const members = [
    [
      {
        name: "Dr. Thant Thant Soe",
        position: "Professor",
        profile: drthantThantSoe,
      },
    ],
    [
      {
        name: "Dr. Khin Phyu Phyu Win",
        position: "Associate Professor",
        profile: drKhinPhyuPhyuWin,
      },
    ],
    [
      {
        name: "Daw San San Lwin",
        position: "Lecturer",
        profile: dawSanSanLwin,
      },
      {
        name: "Daw Aye Aye Moe",
        position: "Lecturer",
        profile: dawAyeAyeMoe,
      },
      {
        name: "U Ko Ko Myo",
        position: "Assistant Lecturer",
        profile: uKoKoMyo,
      },
    ],
    [
      { name: "Daw Yin Mar Oo", position: "Tutor", profile: dawYinMarOo },
      {
        name: "Daw Phyo Nyein Thu",
        position: "Tutor",
        profile: dawPhyoNyeinThu,
      },
      { name: "Daw Nu Nu Nwe", position: "Tutor", profile: dawNuNuNwe },
      {
        name: "Daw Yee Yee Thant",
        position: "Tutor",
        profile: dawYeeYeeThant,
      },
    ],
    [
      { name: "Daw Moh Moh Win", position: "Tutor", profile: dawMohMohWin },
      { name: "Daw Yadanar Oo", position: "Tutor", profile: dawYadanarOo },
      {
        name: "Daw Yae Mon Aung",
        position: "Tutor",
        profile: yaeMonAung,
      },
      { name: "Daw Thuzar Myint", position: "Tutor", profile: dawThuzarMyint },
    ],
    [
      {
        name: "Daw Htet Htet San Oo",
        position: "Tutor",
        profile: dawHtetHtetSanOo,
      },
      { name: "Daw Thuzar Win", position: "Tutor", profile: dawThuzarWin },
    ],
  ];

  const degrees = ["No Data"];
  const programs = null;
  const facultyResearch = [
    {
      name: "-",
      research: ["-"],
    },
  ];

  const activities = [
    { title: "Departmental Staff Training", image: mathActiOne },
    // { title: "Departmental Staff Training", image: mathActiTwo },
    { title: "Departmental Staff Training", image: mathActiThree },
    { title: "Departmental Staff Training", image: mathActiThreeOne },
    {
      title: "Traditional Religious Ceremony",
      image: mathActiFour,
    },
  ];

  return (
    <>
      <div className="ml-32 mr-12 mobile-margin-x-zero">
        <MathematicsDepartmentHeading />

        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default Mathematics;
