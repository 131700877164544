import { DataRequire } from "../components";

function Maas() {
  return (
    <>
      <DataRequire />
    </>
  );
}

export default Maas;
