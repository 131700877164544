import React from 'react'

const DataRequire = () => {
  return (
    <div className='w-full h-[50vh] text-center flex items-center justify-center text-5xl'>
      Coming Soon....................
    </div>
  )
}

export default DataRequire