import React, { useState } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import { commerceHeadingImg } from "../../assets";
import StatisticsLogo from "../../assets/departmentLogo/Statistics Logo.jpg";

const StatisticsHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(true);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);
  const objectivesData = [
    "To improve and strengthen statistical learning and teaching system",
    "To produce graduate students for professional careers in data collection, data management and analysis in the public or private sector",
    "To develop staff who are actively contributing their knowledge, skills, and experiences to organization development",
    "To develop effective application and analysis of statistics in business and decision making",
  ];
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="center"
          className="text-justify mr-5">
          <HeadingCard
            title="Department of Statistics"
            departmentLogo={StatisticsLogo}
          />{" "}
          <Text
            content=" "
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={commerceHeadingImg}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
          />
          <ClickDropDown
            info="Vision"
            detail="To become an excellent Statistician will inspire rational decision making in all areas of social, commercial and scientific pursuits to serve the needs of regional and national development in research, business, and industry."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="To provide excellent training in scientific data collection, data management, methods and procedures of data analysis with providing in-depth knowledge regarding the theory and application of statistical techniques."
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detailGroup={objectivesData}
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default StatisticsHeading;
