import { BrowserRouter } from "react-router-dom";
import "./mobileResponsive.css";
import { Navbar, Footer } from "./layout";
import RouterConfig from "./RouterConfig";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pt-[165px] overflow-x-hidden mobile-nav-padding ">
          <RouterConfig />
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
