import React, { useState } from "react";
import { ClickDropDown, HeadingCard } from "../../cards";
import { Flex, Image, Text } from "../../ui";
import { commerceHeadingImg } from "../../assets";
import AppliedEcoLogo from "../../assets/departmentLogo/App Eco.jpg";

const AppliedEcoHeading = () => {
  const [showVisionDetail, setShowVisionDetail] = useState(false);
  const [showMissionDetail, setShowMissionDetail] = useState(false);
  const [showObjectivesDetail, setShowObjectivesDetail] = useState(false);
  return (
    <>
      <Flex
        direction="row"
        justify="between"
        align="start"
        className="mt-10 gap-10 mobile-column mobile-center">
        <Flex
          direction="col"
          justify="center"
          align="center"
          className="text-justify mr-5">
          <HeadingCard
            title="Department of Applied Economics"
            departmentLogo={AppliedEcoLogo}
          />
          <Text
            content=" The Department of Applied Economics was established as a major department in 1998. It is one of the most distinguished academic departments in Monywa University of Economics."
            color="rgba(51, 51, 51, 0.8)"
            size="18px"
            weight="400"
            className="mb-1 text-left"
          />
        </Flex>
        <div className="flex flex-col align-center justify-start">
          <Image
            src={commerceHeadingImg}
            width="320px"
            height="250px"
            className="rounded-lg object-cover object-center"
            alt="Commerce Heading"
          />
          <ClickDropDown
            info="Vision"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showVisionDetail}
            setShowDetail={setShowVisionDetail}
            toggleClick={() => {
              setShowVisionDetail(!showVisionDetail);
              setShowMissionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            info="Mission"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showMissionDetail}
            setShowDetail={setShowMissionDetail}
            toggleClick={() => {
              setShowMissionDetail(!showMissionDetail);
              setShowVisionDetail(false);
              setShowObjectivesDetail(false);
            }}
          />
          <ClickDropDown
            className="w-[271px]"
            info="Objectives"
            detail="We are dedicated to create integrated learning society capable of embracing challenges and opportunities of the real world."
            showDetail={showObjectivesDetail}
            setShowDetail={setShowObjectivesDetail}
            toggleClick={() => {
              setShowObjectivesDetail(!showObjectivesDetail);
              setShowVisionDetail(false);
              setShowMissionDetail(false);
            }}
          />
        </div>
      </Flex>
    </>
  );
};

export default AppliedEcoHeading;
