import React from "react";
import { StatisticsHeading } from "../components";
import CommerceSectionsTemplate from "../components/commerce/CommerceSectionsTemplate";
import {
  ayeAyeHtwe,
  eiEiLinn,
  khinSanThint,
  nweNweLinn,
  thanThanMyint,
  waiWaiHtet,
  waiWaiKhin,
  zarWaiWin,
  eiThuZarHtun,
  myintMoeMoeKhin,
  lwinMoeThu,
  yinYinTheint,
  zawMyolwin,
  stsActOne,
  stsActTwo,
  stsActThree,
  stsActFour,
  stsActFive,
  stsActSix,
} from "../assets";

const StatisticsDepartment = () => {
  const curriculums = [
    {
      title: "Bachelor of Economics (Statistics) & BEcon (Honours)",
      degrees: [
        "Business English",
        "Introductory Microeconomics",
        "Introductory Macroeconomics",
        "Fundamentals of Statistics",
        "Regression Analysis",
        "Applied Time Series Analysis",
        "Data Science",
        "Statistical Decision Making",
      ],
    },
    {
      title: "Master of Economics (Statistics)",
      degrees: [
        "Macroeconometrics",
        "Microeconometrics",
        "Probability and Distribution Theory",
        "Sample Survey Theory and Methods",
        "Econometrics Theory",
        "Statistical Research Methodology",
        "Financial and Insurance Statistics",
        "Advanced Nonparametric Statistics",
      ],
    },
    {
      title: "Doctor of Philosophy (Ph.D.) in Statistics",
      degrees: [
        "Advanced Time Series Analysis",
        "Financial Econometrics",
        "Advanced Statistical Decision Theory",
        "Advanced Multivariate Analysis",
        "Population and Development Statistics",
        "Advanced Sample Survey Theory",
        "Spectral Analysis of Time Series",
        "Advanced Strategic Management",
      ],
    },
  ];

  const headInfo = {
    name: "Myint Moe Moe Khin",
    profileImage: myintMoeMoeKhin,
    department: "Department of Statistics",
    position: "Professor and Head",
    passage:
      "She takes the responsibility of managing the Statistics Department since 2019. She earned her BEcon (Statistics) from Yangon University of Economics in 1993. She attained her MEcon (Statistics) at Yangon University of Economics in 1999 and PhD (Statistics) at Monywa University of Economics in 2016. Her specialized academic fields are Time Series Analysis and Multivariate Analysis. She has the total working experience of 26 years and 4 months.  She has been delivering lecture of Time Series Analysis and Research Methodology to Master and PhD students for 20 years. She worked as an examiners and viewers of researches under Master Programs and PhD programs of Monywa and Meikthila University of Economics. She is also the Program Director of Master of Applied Statistics Programme and Post Graduate Diploma in Research Studies offered by Department of Statistics, Monywa University of Economics.",
  };

  const members = [
    [
      {
        name: "Dr. Myint Moe Moe Khin",
        position: "Professor (Head)",
        profile: myintMoeMoeKhin,
      },
    ],
    [
      {
        name: "Daw Aye Aye Htwe",
        position: "Associate Professor",
        profile: ayeAyeHtwe,
      },
      {
        name: "Daw Than Than Myint",
        position: "Associate Professor",
        profile: thanThanMyint,
      },
      {
        name: "Daw Khin Than Sin",
        position: "Associate Professor",
        profile: khinSanThint,
      },
      {
        name: "Daw Nwe Nwe Lin",
        position: "Associate Professor",
        profile: nweNweLinn,
      },
      {
        name: "Daw Ei Thuzar Tun",
        position: "Associate Professor",
        profile: eiThuZarHtun,
      },
    ],
    [
      {
        name: "U Zaw Myo Lwin",
        position: "Associate Lecturer",
        profile: zawMyolwin,
      },
      {
        name: "Daw Zar Zar Win",
        position: "Associate Lecturer",
        profile: zarWaiWin,
      },
    ],
    [
      {
        name: "Daw Wai Wai Khin",
        position: "Tutor",
        profile: waiWaiKhin,
      },
      {
        name: "Daw Lwin Moe Thu",
        position: "Tutor",
        profile: lwinMoeThu,
      },
      {
        name: "Daw Wai Wai Htet",
        position: "Tutor",
        profile: waiWaiHtet,
      },
      {
        name: "Daw Yin Yin Thein",
        position: "Tutor",
        profile: yinYinTheint,
      },
      {
        name: "Daw Ei Ei Lin",
        position: "Tutor",
        profile: eiEiLinn,
      },
    ],
  ];

  const degrees = [
    "Bachelor of Economics (Statistics)",
    "Bachelor of Economics Honours (Statistics)",
    "Master of Economics (Statistics)",
    "Ph.D. in Statistics",
  ];
  const programs = null;
  const facultyResearch = [
    {
      name: "Dr. Than Than Aye (Professor/Head)",
      research: [
        "Time Series Analysis in Economic Forecasting. MUE Journal, 2020.",
        "Multivariate Analysis in Business Decision Making. MUE Research Journal, 2021.",
        "Advanced Statistical Inference in Financial Studies. MUE Journal, 2022.",
      ],
    },
    {
      name: "Daw Mi Mi Thwe (Lecturer)",
      research: [
        "Econometric Modeling in Social Statistics. MUE Research Journal, 2023.",
        "Statistical Decision Making for Business Optimization. MUE Journal, 2024.",
      ],
    },
    {
      name: "Daw Thida Aye (Assistant Lecturer)",
      research: [
        "Applied Statistics in Health Economics. MUE Seminar, 2022.",
        "Regression Analysis Techniques in Public Sector Studies. MUE Journal, 2023.",
      ],
    },
  ];

  const activities = [
    { title: "", image: stsActOne },
    { title: "", image: stsActTwo },
    { title: "", image: stsActThree },
    { title: "", image: stsActFour },
    { title: "", image: stsActFive },
    { title: "", image: stsActSix },
  ];

  return (
    <>
      <div className="ml-32 mr-12 mobile-margin-x-zero">
        <StatisticsHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default StatisticsDepartment;
