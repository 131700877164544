import React from "react";
import { Flex, Table, Text } from "../../ui";

const PhdFaculty = () => {
  const headers = [
    "No",
    "Department",
    "Current Number",
    "Rector/ Pro-rector",
    "Clerk",
  ];
  const data = [
    [1, "Rector Office", 3, 1, 1],
    [2, "Pro-Rector Office (Admin/ Finance)", 2, 1, 1],
    [3, "Pro-Rector Office (Academic Affairs)", 2, 1, 1],
    [4, "Pro-Rector Office (IRO)", 2, 1, 1],
  ];

  return (
    <Flex
      direction="col"
      justify="center"
      align="center"
      style={{ padding: "20px" }}>
      <Text
        content="Faculty Ph.D"
        size="25px"
        weight="700"
        color="#333"
        className="mt-5 mb-3"
      />

      <Table headers={headers} data={data} className="department-table" />
    </Flex>
  );
};

export default PhdFaculty;
