import React from "react";
import { Table, Text } from "../../ui";

const AFDFaculty = () => {
  const topHeaders = ["Admin", "Finance", "Engineer"];
  const headers = ["Position", "No.", "Position", "No.", "Position", "No."];

  const data = [
    ["Head of Department", 1, "Head of Division", 1, "Assistant Engineer", 2],
    ["Head of Division", 1, "Head of Section", 1, "Lower Division Clerk", 2],
    ["Head of Section", 1, "Accountant (1)", 2, "Driver", 1],
    ["Office Supreme Attendant", 2, "Accountant (2)", 2, "Electric", 1],
    ["Upper Division Clerk", 2, "Storekeeper (2)", 1, "", ""],
    ["Lower Division Clerk", 3, "Accountant (4)", 5, "", ""],
    ["Total", 10, "Total", 12, "Total", 5],
    ["Area staff", 22, "", "", "", ""],
    ["Daily staff", 3, 1, "", "", ""],
    ["General worker", 11, "", "", "", ""],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      <Text
        content="Admin & Finance Department"
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table
        topHeaders={topHeaders}
        headers={headers}
        data={data}
        className="department-details-table"
      />
    </div>
  );
};

export default AFDFaculty;
