import React from 'react'
import { DataRequire } from '../../components'

const AdminDepartment = () => {
  return (
    <div>
      <DataRequire/>
    </div>
  )
}

export default AdminDepartment