import React from "react";
import { Text, Table } from "../../ui";

const Graduates = () => {
  const headers = ["Sr.No", "Degree", "Number of Graduates"];
  const data = [
    [1, "Phd", 37],
    [2, "Master (MEco,MCom,MAct,MBA)", 917],
    [3, "Master of Business Administration(MBA)", 576],
    [4, "Master of Public Administration (MPA) ", 425],
    [5, "Master of Applied Statistics (MAS)", 14],
    [6, "Master of Marketing Management(MMM)", 10],

    [7, "Diploma (Dip.DS,DBS,PGDRS)", 10],
    [8, "Graduate (BEco,BCom,BBA,BAct)", 720],
    [9, "Honours (Beco,BCom,BBA,BAct)", 663],
    ["", "Total", 13710],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      {" "}
      <Text
        content="Graduated Students"
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table headers={headers} data={data} className="aa-faculty-table" />
    </div>
  );
};

export default Graduates;
