import { HeroActivities, HeroBody, HeroChart, HeroHeader } from "../components";

function Hero() {
  return (
    <>
      {" "}
      <HeroHeader />
      <div className="ml-32 mr-32 mobile-margin-x-zero">
        <HeroBody />
        <HeroChart />
        <HeroActivities />
      </div>
    </>
  );
}

export default Hero;
