import { Image, Text, Flex } from "../ui";
function ProfileCard({ image, name, position }) {
  return (
    <>
      <Flex direction="col" className="gap-3">
        <Image
          src={image}
          alt={name}
          width="150px"
          height="172px"
          className="m-7  object-cover"
        />
        <Text
          color="black"
          size="20px"
          weight="400"
          className="text-justify whitespace-nowrap"
          content={name}
        />
        <Text
          color="black"
          size="16px"
          weight="400"
          className="text-justify whitespace-nowrap"
          content={position}
        />
      </Flex>
    </>
  );
}

export default ProfileCard;
