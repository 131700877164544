import React from "react";
import { Text, Table } from "../../ui";

const DistanceStudents = () => {
  const headers = ["Sr.", "State & Region", "First Year", "Second Year"];
  const data = [
    [1, "Kachin", 194, 194],
    [1, "Chin", 196, 196],
    [1, "Sagaing", 194, 194],
    [1, "Shan", 194, 194],
    [1, "Magway", 194, 194],
    [1, "Other", 194, 194],
    ["", "Total", 22, 22],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      <Text
        content="Distance Education Students"
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table headers={headers} data={data} className="aa-faculty-table" />
    </div>
  );
};

export default DistanceStudents;
