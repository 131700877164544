import React from "react";
import GeographyDepartmentHeading from "../../components/sup-departments-headings/GeographyDepartmentHeading";
import {
  khinOhnmar,
  kayThweWin,
  nuNuWai,
  tinMarMoe,
  tinHtarHlaing,
  geoActi1,
  geoActi3,
  geoAct2,
  geoAct4,
  geoAct5,
  geoAct6,
} from "../../assets";
import CommerceSectionsTemplate from "../../components/commerce/CommerceSectionsTemplate";
const Geography = () => {
  const curriculums = [
    {
      degrees: ["Geography of Myanmar"],
    },
  ];

  const headInfo = {
    name: "Dr. Khin Ohmmar",
    position: "Professor & Head",
    profileImage: khinOhnmar,
    department: "Department of Geography",
  };
  const members = [
    [
      {
        name: "Dr. Khin Ohmmar",
        position: "Professor & Head",
        profile: khinOhnmar,
      },
    ],
    [
      {
        name: "Daw Kay Thwe Win",
        position: "Tutor",
        profile: kayThweWin,
      },
      { name: "Daw Nu Nu Wai", position: "Tutor", profile: nuNuWai },
    ],
    [
      {
        name: "Daw Tin Mar Moe",
        position: "Part-time Tutor",
        profile: tinMarMoe,
      },
      {
        name: "Daw Tin Htar Hlaing",
        position: "Part-time Tutor",
        profile: tinHtarHlaing,
      },
    ],
  ];
  const degrees = ["No Data"];
  const programs = null;
  const facultyResearch = [
    {
      name: "Dr.Khin Ohn Mar (Professor & Head)",
      research: [
        "Analysis on Spatial Distribution Pattern of Mobile Phone Shops in Pakokku, Project Paper (2022-2023)",
      ],
    },
    {
      name: "Daw Nwe Ni Thaw (Lecturer)",
      research: [
        "A Geographical Study on Urban Land Use of Ward No (15) in Pathein, Peer Reviewed Journal (2022) (Vol.12, Pg-47 to 61)",
      ],
    },
    {
      name: "Daw Kay Thwe Win (Tutor)",
      research: [
        "Analysis of Population Pressure on Agricultural Lands in Budalin Township, University Research Journal (2023, Vol.13, Pg-35 to 51)",
      ],
    },
    {
      name: "Daw Nu Nu Wai (Tutor)",
      research: [
        "The Effects of Migration on the Urbanization in Monywa, University Research Journal (2023, Vol.13, Pg-15 to 34)",
        "Assessment on the Influencing Factors of Urban Area Expansion in Monywa, University Research Journal (2024, Vol.14, No.2, Pg-28 to 40)",
      ],
    },
  ];

  const activities = [
    { title: "", image: geoActi1 },
    { title: "", image: geoAct2 },
    { title: "", image: geoActi3 },
    { title: "", image: geoAct4 },
    { title: "", image: geoAct5 },
    { title: "", image: geoAct6 },
  ];

  return (
    <div className="pl-32 pr-32 mobile-margin-x-zero">
      <GeographyDepartmentHeading />

      <CommerceSectionsTemplate
        curriculumsTypes={curriculums}
        headMaster={headInfo}
        groupMembers={members}
        researches={facultyResearch}
        news={activities}
        offeredDegrees={degrees}
        hrdProgrammes={programs}
      />
    </div>
  );
};

export default Geography;
