import React, { useState, useEffect } from "react";
// import { FaFacebook } from "react-icons/fa";
import { Flex, Icon, Image, Text } from "../ui";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import UniLogo from "../assets/departmentLogo/School_loGo__2_-removebg.png";
import MainBuilding from "../assets/hero/MainBuilding.jpg";
import { unionOne, unionThree, unionTwo } from "../assets";
import { TiLocation } from "react-icons/ti";

function Footer() {
  const images = [MainBuilding, unionOne, unionTwo, unionThree];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div
        style={{ backgroundColor: "#FFD700" }}
        className="w-full h-5 flex relative -z-50 justify-center translate-y-1 items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[100vw] -translate-y-2"
          height="24"
          viewBox="0 0 1440 21"
          fill="none">
          <path
            d="M0 9.69732C651.724 -8.79817 906.205 3.78671 1440 9.69732V20.6973L734.5 9.69732L0 20.6973V9.69732Z"
            fill="#FFD700"
          />
        </svg>
      </div>
      <Flex
        direction="row"
        justify="center"
        align="center"
        className="gap-10 pl-40 pr-40 p-5 mobile-column"
        backgroundColor="rgba(41, 74, 112, 1)">
        <Image
          src={images[currentImageIndex]}
          width="330px"
          height="175px"
          className="min-w-[328px] pt-5 "
          alt="Main Building"
        />
        <Flex direction="col" justify="center">
          <Image src={UniLogo} width="100px" height="auto" alt="Site Logo" />
          <Text
            size="18px"
            color="white"
            content="Monywa University Of Economics"
            backgroundColor="transparent"
            className="mt-3 mb-3 whitespace-nowrap "
          />
          <Flex
            direction="row"
            justify="center"
            align="center"
            className="gap-2">
            {/* <Icon IconComponent={FaFacebook} color="white" size="30px"  /> */}
          </Flex>
        </Flex>
        <div className="flex flex-row justify-start md:gap-40 items-start ">
          <Flex direction="col" justify="center">
            <Text
              size="18px"
              color="white"
              content="Quick Links"
              backgroundColor="transparent"
              className="text-white whitespace-nowrap"
            />
            <ul typeof="dot" className="mt-3 text-white text-left">
              <Link to="/">
                <li className="text-base flex flex-row gap-2">
                  <BsCircleFill size={6} />
                  Home
                </li>
              </Link>
              <Link to="/about">
                <li className="text-base flex flex-row gap-2 whitespace-nowrap">
                  <BsCircleFill size={6} />
                  About MUE
                </li>
              </Link>
              <Link to="/faculty">
                <li className="text-base flex flex-row gap-2">
                  <BsCircleFill size={6} />
                  Faculty
                </li>
              </Link>
              <Link to="/e-library">
                <li className="text-base flex flex-row gap-2 whitespace-nowrap">
                  <BsCircleFill size={6} />
                  E-Library
                </li>
              </Link>
            </ul>
          </Flex>

          <Flex direction="col" justify="center" className="gap-3 ml-6">
            <div className="flex  flex-col justify-start items-start ">
              <Text
                size="18px"
                color="white"
                content="Contact Us"
                backgroundColor="transparent"
                className="text-white mb-1 -translate-x-16 text-left whitespace-nowrap"
              />
            </div>
            <Flex
              direction="col"
              justify="center"
              align="center"
              className="translate-x-1">
              <Flex
                direction="row"
                justify="start"
                align="start"
                className="gap-1">
                <Icon
                  IconComponent={TiLocation}
                  size="30"
                  className="min-w-6 min-h-6"
                  color="white"
                />
                <Text
                  size="16px"
                  color="white"
                  className="text-left max-w-96 pl-3 line-clamp-3 footer-address"
                  content="Kyauk-ka Road, Nandawon Quarter, Monywa Township, Sagaing Region, Myanmar"
                  backgroundColor="transparent"
                />
              </Flex>
              <Flex
                direction="row"
                justify="start"
                align="start"
                className="gap-2  mt-3 mb-3">
                <Icon
                  IconComponent={BiSolidPhoneCall}
                  size="25"
                  color="white"
                />
                <Text
                  size="16px"
                  color="white"
                  content="+959757560883"
                  backgroundColor="transparent"
                />
              </Flex>
              <Flex
                direction="row"
                justify="start"
                align="start"
                className="gap-2 ">
                <Icon
                  IconComponent={IoIosMail}
                  size="25px"
                  className="min-w-6 min-h-6"
                  color="white"
                />
                <Text
                  size="16px"
                  color="white"
                  content="muerectoroffice@gmail.com"
                  backgroundColor="transparent"
                />
              </Flex>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <div
        style={{ backgroundColor: "#FFD700" }}
        className="w-full h-8 flex justify-center items-center">
        Copyright 2024. All right reserved by MUE
      </div>
    </>
  );
}

export default Footer;
