import React from "react";
import { Table, Text } from "../../ui";

const UnderStudents = () => {
  const headers = ["Level", "Eco", "Stats", "Commerce", "Act", "Mgt", "Total"];
  const data = [
    ["First Year", 194, 194, 194, 194, 194, 194],
    ["Second", 196, 196, 196, 196, 196, 196],
    ["Third", 182, 182, 182, 182, 182, 182],
    ["Final", 191, 191, 191, 191, 191, 191],
    ["H1", 23, 23, 23, 23, 23, 23],
    ["H2", 12, 12, 12, 12, 12, 12],
    ["H3", 22, 22, 22, 22, 22, 22],
    ["Total", 820, 820, 820, 820, 820, 820],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      <Text
        content="Undergraduate Students "
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table headers={headers} data={data} z className="aa-faculty-table" />
    </div>
  );
};

export default UnderStudents;
