import React from "react";
import { DataRequire } from "../components";

const Prospectus = () => {
  return (
    <div>
      <DataRequire />
    </div>
  );
};

export default Prospectus;
