import React from "react";
import { AppliedEcoHeading } from "../components";
import CommerceSectionsTemplate from "../components/commerce/CommerceSectionsTemplate";
import {
  caFive,
  caFour,
  caOne,
  caSix,
  caThree,
  caTwo,
  thidaHtay,
  htinPaw,
  waiWaiLin,
  roiAung,
  aungMyoHtun,
  mieMieKyaw,
} from "../assets";

const curriculums = [
  {
    title: "Bachelor of Economics (Trade and Logistics)",
    degrees: [
      "Introductory Microeconomics",
      "Introductory Macroeconomics",
      "Money and Banking",
      "Quantitative Analysis",
      "Operations Management",
      "Mathematics for Economics",
      "English for Logistics",
      "International Economic Law",
      "International Trade",
      "Trade Statistics and Economic Indicators",
      "Organizational Management",
      "Geography of Myanmar",
      "English for Logistics",
      "Law of International Trade",
      "Professional English for Students of Logistics",
      "Trade Policy and Procedure in Myanmar",
      "Applied Econometrics",
      "Marketing Management",
      "Introduction to Logistics Management",
      "Public Finance",
      "Organizational Behavior",
      "English for Trade Fairs and Events",
      "Investment Policy and Procedure in Myanmar",
      "Applied Time Series Analysis",
      "Research Methodology",
      "Supply Chain Management",
      "Introduction to International Relations",
      "International Business Management",
      "Trade and Development",
      "Strategic Logistics Management",
      "Logistics Analytics",
      "Business Finance",
    ],
  },
  {
    title: "Master of Development Studies",
    degrees: [
      "Principles of Microeconomics",
      "Principles of Macroeconomics",
      "Evolution of Development Thinking",
      "Political Economy",
      "Money, Banking and Finance",
      "ASEAN Economies and Regional Integration",
      "Agricultural Transformation and Rural Development",
      "International Trade",
      "Research Methodology",
      "Education and Human Resource Development",
      "SMEs Development and Industrialization",
      "Environmental and Natural Resource Economics",
      "Public Policy and Administration",
      "Regional and Urban Development",
      "Infrastructure and Development",
      "Globalization and Development",
      "General Management",
    ],
  },
];
const headInfo = {
  name: "Dr. Thidar Htay",
  profileImage: thidaHtay,
  department: "Department of Applied Economics",
  position: "Professor (Head)",
  passage:
    "Lorem ipsum dolor sit amet consectetur. Turpis quam imperdiet nunc scelerisque. Quisque pellentesque faucibus in urna a mi amet. Augue dui lacus aliquam posuere varius nisi nulla sit. Eget diam ut ullamcorper vulputate at lobortis pellentesque hendrerit quis. Nibh pulvinar aliquet porttitor arcu erat lobortis. Tempor orci ac non rhoncus massa amet in felis fringilla. Feugiat justo mauris bibendum morbi neque gravida ornare odio est. Non mi eget tristique proin quam ullamcorper ac. Quam morbi eu non sapien urna nibh.",
};
const members = [
  [
    {
      name: "Dr. Thida Htay",
      position: "Professor (Head)",
      degree: "B Com (Hons), M Com, MBA (IUJ), Ph D (Commerce)",
      profile: thidaHtay,
    },
  ],
  [
    {
      name: "U Htin Paw",
      position: "Assicioate Professor",
      degree: "B Com (Hons), M Com",
      profile: htinPaw,
    },
  ],
  [
    {
      name: "Daw Wai Wai Lwin",
      position: "Assistant Lecturer",
      degree: "B Com (Hons), M Com, MBA (IUJ), Ph D (Commerce)",
      profile: waiWaiLin,
    },
  ],
  [
    {
      name: "Daw Roi Aung",
      position: "Tutor",
      degree: "B Com (Hons), M Com",
      profile: roiAung,
    },
    {
      name: "U Aung Myo Htun",
      position: "Tutor",
      degree:
        "BA( Business Science), Dip in Business Accounting, M Com,PhD(Prelim)",
      profile: aungMyoHtun,
    },
    {
      name: "Daw Mie Mie Kyaw",
      position: "Tutor",
      degree: "B Com (Hons), M Com, MBA (IUJ), Ph D (Commerce)",
      profile: mieMieKyaw,
    },
  ],
];
const degrees = ["Bachelor of Economics   (Trade & Logistics)"];
const programs = [
  "Master of Development Studies (MDevS)",
  "Post Graduate Diploma in Development Studies (Dip.DS)",
];
const facultyResearch = [
  {
    name: "Dr. Thida Htay",
    research: [
      "A Qualitative Study of Parental Perceptions of Barriers and Enablers of Girls’ Education in Monywa, Myanmar, 2021, IDRC-CRDI",
      "Economic Cooperation between the Republic of Korea and Myanmar, 2022 Proceedings KRI-RSA International Public Policy Symposium, 2022, Page- 493-510",
      "Costs and Benefits Analysis of Water Supply Project in Donhee Town, Naga Region, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, Vol.14, No.2, Page- 1-15",
    ],
  },
  {
    name: "Daw Wai Wai Lwin",
    research: [
      "Contribution of Groundnut Production to Agriculture Sector in Myanmar, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, Vol.14, No.1, Page- 127-138",
    ],
  },
  {
    name: "Daw Roi Aung",
    research: [
      "Relationship between Agricultural Inputs and Agricultural Production in Myanmar, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, Vol.14, No.1, Page- 153-166",
    ],
  },
  {
    name: "U Aung Myo Htun",
    research: [
      "The Relationship between Inflation and Budget Deficit in Myanmar, Silver Jubilee Symposium Founders Day of Monywa University of Economics International Research Journal, 2024, Vol.14, No.2, Page- 190-201",
    ],
  },
];
const activities = [
  { title: "Bla Bla bla bla", image: caOne },
  { title: "Bla Bla bla bla", image: caTwo },
  { title: "Bla Bla bla bla", image: caThree },
  { title: "Bla Bla bla bla", image: caFour },
  { title: "Bla Bla bla bla", image: caFive },
  { title: "Bla Bla bla bla", image: caSix },
];

const AppliedEcoDepartment = () => {
  return (
    <>
      <div className="mobile-margin-x-zero ml-32 mr-32">
        <AppliedEcoHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default AppliedEcoDepartment;
