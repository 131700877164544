import React from "react";
import { Table, Text } from "../../ui";

const GradePoint = () => {
  const headers = [
    "Sr.No",
    "Marks",
    "Grade",
    "Undergraduate Courses",
    "Grade",
    "Honours / Qualifying/ Master / PhD",
  ];

  const data = [
    ["1", "75 and above", "5", "Pass", "5", "Pass"],
    ["2", "75 and above", "5", "Pass", "5", "Pass"],
    ["3", "75 and above", "5", "Pass", "5", "Pass"],
    ["4", "75 and above", "5", "Pass", "5", "Pass"],
    ["5", "75 and above", "5", "Pass", "5", "Pass"],
  ];

  return (
    <>
      {" "}
      <Text
        content="Grade Points"
        size="30px"
        weight="400"
        className="text-left ml-0"
      />
      <div
        className="h-1 mb-3 w-16  hover:w-40 hover:duration-1000"
        style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
      <Table headers={headers} data={data} />
    </>
  );
};

export default GradePoint;
