import { PiDiamondsFourFill } from "react-icons/pi";
import { Flex, Image, Text } from "../ui";

function OfferedDegreeCard({ image, typeOfDegree, majors, justifyStart }) {
  return (
    <>
      <Flex
        direction={justifyStart ? "rowReverse" : "row"}
        justify={justifyStart ? "between" : "start"}
        align="start"
        className="mb-11  text-start items-center mobile-start  mobile-column">
        <div
          className="mr-10"
          style={{
            backgroundColor: "rgba(73, 104, 142, 1)",
            maxWidth: "600px",
          }}>
          <Image
            src={image}
            alt={typeOfDegree}
            height="270px"
            width="360px"
            className="-translate-y-2 min-w-[359px] object-cover object-center mobile-hidden -translate-x-2"
          />
        </div>
        <Flex direction="col" justify="start" align="start" className="">
          <Text
            color="black"
            content={typeOfDegree}
            weight="500"
            size="20px"
            className="mb-3 text-left"
          />
          <ul>
            {majors &&
              majors.map((major, index) => (
                <li className="flex flex-row mb-3 gap-2" key={index}>
                  <PiDiamondsFourFill size={20} />
                  <Text
                    color="black"
                    content={major}
                    weight="500"
                    size="16px"
                  />
                </li>
              ))}
          </ul>
        </Flex>
      </Flex>
    </>
  );
}

export default OfferedDegreeCard;
