import { nilarMyint, ourMotto, ourVision, siteLogo } from "../../assets";
import { Flex, Image, Text } from "../../ui";
function HeroBody() {
  return (
    <>
      <Flex
        direction="colReverse"
        justify="center"
        align="start"
        className="ml-3 gap-7 mobile-center md:flex-row">
        <div className="gap-4 flex flex-col justify-start items-center w-1/3 hero-vision-mission">
          <Image src={ourMotto} width="61px" height="61px" alt="Our Motto" />
          <div
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
            className="h-1 w-11  hover:w-32 hover:duration-1000"></div>
          <Text
            size="30px"
            color="rgba(0, 51, 102, 1)"
            className="whitespace-nowrap"
            content="OUR MOTTO"
          />
          <Text
            size="18px"
            color="rgba(51, 51, 51, 1)"
            content="• Learn, Insight, Achieve"
            className="mb-8 text-justify whitespace-nowrap"
          />
          <Image src={ourVision} width="61px" height="61px" alt="Our Vision" />
          <div
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
            className="h-1 w-11  hover:w-32 hover:duration-1000"></div>
          <Text
            size="30px"
            color="rgba(0, 51, 102, 1)"
            className="whitespace-nowrap"
            content="OUR VISION"
          />
          <Text
            size="18px"
            color="rgba(51, 51, 51, 1)"
            content="• To become a world-class university nurturing responsible intellectuals and professionals in Economics."
            className="mb-8 w-96 text-justify"
          />
          <Image src={ourMotto} width="61px" height="61px" alt="Our Motto" />
          <div
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
            className="h-1 w-11  hover:w-32 hover:duration-1000"></div>
          <Text
            size="30px"
            color="rgba(0, 51, 102, 1)"
            className="whitespace-nowrap"
            content="OUR MISSION"
          />
          <ul className="list-disc w-96">
            <li className="text-justify">
              • To provide a learning environment in which faculty and staff can
              discover continuously, examine critically, and transmit
              sustainably the knowledge, wisdom and values
            </li>
            <li className="text-justify">
              • To empower the student engagement in local and global
              communities in accordance with the changing environment
            </li>

            <li className="text-justify">
              • To cultivate habits of lifelong learning and student-centered
              teaching and learning{" "}
            </li>
            <li className="text-justify">
              • To emphasize creative and cutting-edge conducting
              state-of-the-art research 
            </li>
          </ul>
        </div>
        <Flex
          direction="col"
          justify="start"
          align="start"
          className="pl-9 pr-2 pt-8 shadow-md shadow-gray-700 m-5 pb-10">
          <Flex direction="row" justify="evenly">
            <Flex direction="row" justify="start">
              <Image
                src={nilarMyint}
                height="205px"
                width="155px"
                alt="Daw Nilar Myint"
              />
              <Flex
                direction="col"
                justify="start"
                align="start"
                className="gap-2 ml-5">
                <Text
                  color="rgba(51, 51, 51, 1)"
                  className="font-medium"
                  content="Prof.Dr. Nilar Myint Htoo"
                  size="20px"
                />
                <Text
                  color="rgba(51, 51, 51, 1)"
                  className="font-medium"
                  content="Rector"
                  size="16px"
                />
                <Text
                  color="rgba(51, 51, 51, 1)"
                  className="font-medium"
                  size="16px"
                  content="(Monywa University of Economics)"
                />
              </Flex>
            </Flex>
            <Image
              src={siteLogo}
              width="120px"
              height="147px"
              className="mr-5 mobile-hidden"
              alt="Site Logo"
            />
          </Flex>
          <Text
            color="rgba(51, 51, 51, 1)"
            className="font-medium mb-2 text-left"
            content="Rector's Message"
            weight="500"
            size="18px"
          />
          <div
            style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
            className="h-1 w-11  mb-3 hover:w-32 hover:duration-1000"></div>
          <Text
            color="rgba(51, 51, 51, 1)"
            className="font-medium mb-2 pr-4 text-justify"
            content="Welcome to Monywa University of Economics. It was founded as Monywa Institute of Economics in 1998 and upgraded to Monywa University of Economics (MUE) in 2014. Monywa University of Economics offers not only academic programs awarding Bachelor's, Honours, Master's, and Doctoral degrees but also professional online programs providing Certificates, Diplomas, and Master's degrees. In the years back and forth, our university offers quality courses associated with quality teaching related to the fields of Economics.  Monywa University of Economics created these courses for the sole purpose of producing talented people in the workforce equipped with sound theoretical as well as practical knowledge on the respective courses."
            weight="400"
            size="18px"
          />
          <Text
            color="rgba(51, 51, 51, 1)"
            className="font-medium pr-4 mb-2 text-justify"
            content=" They will be able to create better future for Myanmar society. Our courses will benefit quite effectively for the students and and our graduates have been highly appreciated in mind-and-high positions in government organizations, non-government organizations as well as the business organizations or private sector. In the years to come, the courses will be refined again and again to meet international academic standard as well as to cope with the changing economic environment and needs in Myanmar. Nowadays, Myanmar is changing towards a new era calling for skills and knowledge to manage economic and social challenges. In an era of globalization, and information society, we endeavor to collaborate with local, national, regional, and global partners. In MUE, there are many scholarship programmes, student exchange programmes and internship programmes jointly conducted by local and international universities and organizations. Teaching and research are far from mutually exclusive; they are, in fact, complementary activities. Research is critical to the advancement of MUE reputation among universities. Therefore, MUE supports to improve research culture and research infrastructure that are necessary for the institution to continue its upward trajectory. We are looking forward to welcoming all students and learners to our courses and hope that students will have the opportunity of weaving a social network where in the present and future, there will be more coordination, cooperation and collaboration among themselves towards the betterment of their own personal lives and for the community they serve."
            weight="400"
            size="18px"
          />
        </Flex>
      </Flex>
    </>
  );
}

export default HeroBody;
