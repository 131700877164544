import React from 'react'
import { DataRequire } from '../components'

const Project = () => {
  return (
    <div>
      <DataRequire/>
    </div>
  )
}

export default Project