import { location } from "../../assets";
import { Flex, Image, Text } from "../../ui";

function FindOnMaps() {
  return (
    <>
      <Flex
        direction="col"
        justify="center"
        align="center"
        className="pl-32 pr-32 pb-10 pt-10 mobile-margin-x-zero mb-10 bg-gray-100">
        <Text
          content="Find Us On Google Maps"
          size="30px"
          backgroundColor="transparent"
          weight="500"
          className="p- "
        />
        <Text
          content="Lorem ipsum dolor sit amet consectetur. Vitae enim ut platea sit consectetur. Faucibus maecenas enim mauris nibh diam convallis. Integer adipiscing sit quis sed aliquam. Non quis et sem mauris sit. Elementum eget diam lacus sed rutrum ipsum. Non et senectus ipsum ut."
          size="20px"
          backgroundColor="transparent"
          weight="400"
          className="pl-10 pr-10 mb-[30px]"
        />
        <Image src={location} alt="Google Map Location" width="1180px" />
      </Flex>
    </>
  );
}

export default FindOnMaps;
