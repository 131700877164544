import { degreeBg } from "../../assets";

function ContactHeader() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${degreeBg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          minHeight: "444px",
        }}></div>
    </>
  );
}

export default ContactHeader;
