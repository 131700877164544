import { DataRequire } from "../components";

function MasterPlan() {
  return (
    <>
      <DataRequire />
    </>
  );
}

export default MasterPlan;
