import React from "react";
import { Flex } from "../../ui";

const CommerceResearchPaper = ({ facultyResearch }) => {
  return (
    <>
      {facultyResearch.map((research, id) => (
        <Flex
          direction="col"
          justify="start"
          items="start"
          key={id}
          className="m-4 p-8 rounded-3xl mobile-margin-x-zero border-2 border-l-8 border-l-yellow-400 border-blue-950">
          <div className="w-full">
            {" "}
            <p className="mb-5 text-left text-xl font-semibold">
              {research.name}
            </p>
          </div>
          {research.research.map((researchDetail, index) => (
            <Flex direction="row" justify="start" align="start" key={index}>
              <p className="text-xl font-normal text-left">{index + 1}</p>
              <p className="text-xl ml-3 font-normal text-left">
                {researchDetail}
              </p>
            </Flex>
          ))}
        </Flex>
      ))}
    </>
  );
};

export default CommerceResearchPaper;
