import { BiSolidPhoneCall } from "react-icons/bi";
import { TiLocation } from "react-icons/ti";
import { GrMail } from "react-icons/gr";
import { contactInfoBg } from "../../assets";
import { Flex, Text } from "../../ui";

function SendMessage() {
  return (
    <div className="w-full mt-10 gap-14 mobile-margin-x-zero ml-32 mr-32 rounded-lg mb-5 flex flex-row justify-start items-start">
      <div
        className="flex flex-col justify-start gap-2 mobile-hidden"
        style={{
          backgroundImage: `url(${contactInfoBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain", // Adjust this as needed
          backgroundPosition: "top",
          width: "266px", // Match your SVG width
          height: "235px", // Match your SVG height
          borderRadius: "10px",
          padding: "12px",
          margin: "10px",
          border: "1px solid rgba(41, 74, 112, 0.29)",
          boxShadow: "0px 4px 4px 0px rgba(41, 74, 112, 0.25)",
        }}>
        <h2 className="text-base font-medium  pb-16 text-center">
          Contact Info
        </h2>
        <div className="flex flex-row items-center text-left">
          <BiSolidPhoneCall color="#294A70" size={25} className="mr-4" />
          <p className="text-sm">959 977622770</p>
        </div>
        <div className="flex flex-row items-center text-left">
          <GrMail size={25} color="#294A70" className="mr-4" />
          <p className="text-sm">mue@gamil.com</p>
        </div>
        <div className="flex flex-row items-center  text-left">
          <TiLocation
            className="self-start mt-[-8px]"
            size={40}
            color="#294A70"
          />
          <p className="text-sm ml-4">
            Kyauk-ka Road, Nandawon Quarter, Monywa Township, Sagaing Region,
            Myanmar
          </p>
        </div>
      </div>
      <Flex
        direction="col"
        justify="start"
        align="center"
        className="border-2 h-auto shadow-md py-[28px] px-[18px] mb-[25px] send-msg-form">
        <Text
          className="self-start"
          content="Send Message"
          color="#294A70"
          size="24px"
          weight="500"
        />
        <div
          style={{ backgroundColor: "rgba(255, 215, 0, 1)" }}
          className="h-1 w-11  mb-3 hover:w-32 hover:duration-1000 self-start my-[10px]"></div>
        <Text
          className="self-start mb-[20px]"
          content="Lorem ipsum dolor sit amet consectetur. Eget posuere morbi amet feugiat."
          color="rgba(51, 51, 51, 0.80)"
          size="18px"
          weight="400"
        />
        <Flex
          direction="row"
          justify="between"
          align="center"
          className="mobile-column">
          <input
            type="text"
            placeholder="Name"
            className="rounded-xl m-3 pl-5 pt-2 pb-2 pr-5 w-full"
            style={{
              border: "1px solid rgba(255, 215, 0, 1)",
              filter: " drop-shadow(0px 4px 4px rgba(41, 74, 112, 0.30))",
            }}
          />
          <input
            type="text"
            placeholder="Subject"
            className="rounded-xl m-3 pl-5 pt-2 pb-2 pr-5 w-full"
            style={{
              border: "1px solid rgba(255, 215, 0, 1)",
              filter: " drop-shadow(0px 4px 4px rgba(41, 74, 112, 0.30))",
            }}
          />
        </Flex>
        <Flex
          direction="row"
          justify="between"
          align="center"
          className="mobile-column">
          <input
            type="text"
            placeholder="Phone No"
            className="rounded-xl m-3 pl-5 pt-2 pb-2 pr-5 w-full"
            style={{
              border: "1px solid rgba(255, 215, 0, 1)",
              filter: " drop-shadow(0px 4px 4px rgba(41, 74, 112, 0.30))",
            }}
          />
          <input
            type="text"
            placeholder="Email"
            className="rounded-xl m-3 pl-5 pt-2 pb-2 pr-5 w-full"
            style={{
              border: "1px solid rgba(255, 215, 0, 1)",
              filter: " drop-shadow(0px 4px 4px rgba(41, 74, 112, 0.30))",
            }}
          />
        </Flex>
        <textarea
          placeholder="Your Message"
          className="rounded-xl pl-5 pt-2 pb-2 pr-5 w-full mt-5 h-32"
          style={{
            border: "1px solid rgba(255, 215, 0, 1)",
            filter: " drop-shadow(0px 4px 4px rgba(41, 74, 112, 0.30))",
            resize: "vertical", // Optional: prevents resizing of the textarea
          }}
        />
        <button className="contact-us-btn rounded-xl m-2 border-2">
          <p>Send Message</p>
        </button>
      </Flex>
    </div>
  );
}

export default SendMessage;
