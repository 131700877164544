import { classroom, hogward, speakingGuy } from "../../assets";
import { ContactInfoCard } from "../../cards";
import { Text } from "../../ui";

function DegreeContactInfo() {
  return (
    <>
      <Text
        color="rgba(41, 74, 112, 1)"
        content="Contact Information"
        size="30px"
        weight="400"
        className="text-left"
      />
      <div
        className="ml-1 h-1 w-11  mb-3  hover:w-32 hover:duration-1000"
        style={{ left: "30%", backgroundColor: "rgba(255, 215, 0, 1)" }}></div>
      <div className="flex flex-wrap whitespace-normal">
        <ContactInfoCard
          image={hogward}
          program="Academic Affairs"
          phoneNumber="+959 977622770"
        />
        <ContactInfoCard
          image={hogward}
          program="MBA Programme"
          phoneNumber="+959 755513126"
        />
        <ContactInfoCard
          image={hogward}
          program="MPA Programme"
          phoneNumber="+959 789951019"
        />
        <ContactInfoCard
          image={speakingGuy}
          program="MMM Programme"
          phoneNumber="+959 422112470"
        />
        <ContactInfoCard
          image={speakingGuy}
          program="MAS Programme"
          phoneNumber="+959 404035886"
        />
        <ContactInfoCard
          image={speakingGuy}
          program="MBF Programme"
          phoneNumber="+959 941577991"
        />
        <ContactInfoCard
          image={classroom}
          program="MDevS Programme"
          phoneNumber="+959 757543637"
        />
        <ContactInfoCard
          image={classroom}
          program="D.S Programme"
          phoneNumber="+959 755513126"
        />
        <ContactInfoCard
          image={classroom}
          program="DBS Programme"
          phoneNumber="+959 422112470"
        />
      </div>
    </>
  );
}

export default DegreeContactInfo;
