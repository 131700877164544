import React from "react";
import "./index.css";

const Flex = ({
  direction = "row",
  justify = "center",
  align = "center",
  className = "",
  children,
  backgroundColor = "",
  ...props
}) => {
  const directionClass =
    {
      row: "flex-row",
      rowReverse: "flex-row-reverse",
      col: "flex-col",
      colReverse: "flex-col-reverse",
    }[direction] || "flex";

  const justifyClass =
    {
      start: "justify-start",
      center: "justify-center",
      between: "justify-between",
      end: "justify-end",
    }[justify] || "justify-center";

  const alignClass =
    {
      start: "items-start",
      center: "items-center",
      end: "items-end",
    }[align] || "items-center";

  return (
    <div
      {...props}
      className={`flex ${directionClass} ${justifyClass} ${alignClass} ${className} w-full`}
      style={{ backgroundColor: backgroundColor }}>
      {children}
    </div>
  );
};

export default Flex;
