import React from "react";
import { PiCircleFill } from "react-icons/pi";
import { Flex } from "../../ui";

const CommerceCurriculum = ({ curriculums }) => {
  return (
    <>
      {curriculums.map((curriculum, index) => (
        <div
          className="m-4 w-full p-8 rounded-3xl border-2 border-l-8 h-auto max-h-[2000px] flex-wrap border-l-yellow-400 border-blue-950"
          key={index}>
          <Flex
            direction="col"
            justify="between"
            align="start"
            className="flex-wrap md:flex-row">
            <div className="flex flex-col justify-start items-stretch">
              {/* Render first title and degrees */}
              <p className="text-xl md:flex-nowrap whitespace-normal  md:white text-left font-semibold flex flex-wrap justify-start md:justify-center items-center mb-4">
                {curriculum.title}
              </p>

              <div className="flex flex-col md:max-h-[700px]  whitespace-normal justify-start md:justify-between flex-wrap">
                {curriculum.degrees.map((degree, id) => (
                  <p
                    className="text-xl md:flex-nowrap whitespace-normal font-medium mb-2 flex-wrap   flex flex-row justify-start ml-10  max-w-[300px] items-start gap-2"
                    key={id}>
                    <PiCircleFill size={5} className="mt-3" /> {degree}
                  </p>
                ))}
              </div>
            </div>

            {/* Render second title and degreesTwo if they exist */}
            {curriculum.titleTwo && curriculum.degreesTwo && (
              <div className="flex flex-col justify-start items-start ml-8 mr-8">
                <p className="text-xl text-left font-semibold mb-4">
                  {curriculum.titleTwo}
                </p>
                <div className="flex flex-col max-h-[700px] whitespace-normal max-w-[350px] flex-wrap">
                  {curriculum.degreesTwo.map((degreeTwo, id) => (
                    <p
                      className="text-xl font-medium mb-2 flex whitespace-pre-wrap  flex-row flex-wrap md:flex-nowrap justify-start items-center gap-2"
                      key={id}>
                      <PiCircleFill size={5} className="mt-3" /> {degreeTwo}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </Flex>
        </div>
      ))}
    </>
  );
};

export default CommerceCurriculum;
