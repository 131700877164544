import { degreeBg } from "../../assets";
import { Flex, Image, Text } from "../../ui";
import UniLogo from "../../assets/departmentLogo/School_loGo__2_-removebg.png";

function DegreeHeading() {
  console.log(degreeBg);
  return (
    <>
      <div
        className="flex flex-col justify-end items-center pl-10 pr-10 mb-24"
        style={{
          backgroundImage: `url(${degreeBg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "335px",
          width: "100vw",
        }}>
        <Flex
          direction="col"
          justify="center"
          items="bottom"
          className="p-8 bg-white rounded-3xl translate-y-20 max-w-[750px] max-h-[169px] border-2 border-t-8 border-t-yellow-400 border-blue-950 ">
          <Image
            src={UniLogo}
            width="66px"
            height="76px"
            alt="University Logo"
          />
          <Text
            content="The Monywa University of Economics is empowered to award the following degrees and diplomas."
            color="black"
            className="text-center"
          />
        </Flex>
      </div>
    </>
  );
}

export default DegreeHeading;
