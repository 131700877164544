import React from "react";
import "./index.css";

const Image = ({
  src,
  alt = "",
  width = "100%",
  height = "auto",
  className = "",
  ...props
}) => {
  return (
    <img
      src={src}
      {...props}
      alt={alt}
      style={{ width, height }}
      className={className}
    />
  );
};

export default Image;
