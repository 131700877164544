import React from "react";
import {
  AdmissionHeader,
  DegreeTypes,
  GradePoint,
  ProgrammeDuration,
} from "../components";

const Admission = () => {
  return (
    <>
      <div
        style={{ padding: "20px 10px 100px ", minHeight: "2150px" }}
        className="mobile-margin-x-zero">
        <AdmissionHeader />
        <GradePoint />
        <ProgrammeDuration />
        <DegreeTypes />
      </div>{" "}
    </>
  );
};

export default Admission;
