import React from "react";
import { Text, Table } from "../../ui";

const AAFaculty = () => {
  const headers = ["Position", "No."];
  const data = [
    ["Head of Department", 1],
    ["Register", 2],
    ["Assistant Registrar", 1],
    ["Head of Division", 1],
    ["Branch of Clerk", 5],
    ["Upper Division Clerk", 1],
    ["Lower Division Clerk", 3],
    ["Daily staff", 2],
    ["Assistant Sport Instructor", 1],
    ["Sport Instructor", 1],
    ["Total", 18],
  ];

  return (
    <div
      className="flex w-full flex-col justify-center items-center"
      style={{ padding: "20px" }}>
      {" "}
      <Text
        content="Admin Affairs Department"
        size="25px"
        weight="700"
        color="#333"
        className="m-5"
      />
      <Table headers={headers} data={data} className="aa-faculty-table" />
    </div>
  );
};

export default AAFaculty;
