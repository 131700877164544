import { useState, useEffect } from "react";
import { Flex, Image, Text } from "../../ui";
import {
  department,
  faculty,
  instructors,
  offeredDegrees,
  students,
} from "../../assets";

function HeroChart() {
  const chartItems = [
    {
      title: "Department Published",
      image: department,
      number: 8,
      isBorderRight: true,
    },
    {
      title: "Professional Faculty",
      image: faculty,
      number: 291,
      isBorderRight: true,
    },
    {
      title: "2023-2024 Students",
      image: students,
      number: 4366,
      isBorderRight: true,
    },
    {
      title: "Instructors",
      image: instructors,
      number: 200,
      isBorderRight: true,
    },
    {
      title: "Degree Offered",
      image: offeredDegrees,
      number: 2,
      isBorderRight: false,
    },
  ];

  // State to store the current rolling number for each chart item
  const [rollingNumbers, setRollingNumbers] = useState(chartItems.map(() => 0));
  const [isRolling, setIsRolling] = useState(true);

  useEffect(() => {
    chartItems.forEach((item, index) => {
      let count = 0;
      const intervalId = setInterval(() => {
        setRollingNumbers((prevNumbers) => {
          const newNumbers = [...prevNumbers];
          // Simulate rolling from 0-9 repeatedly
          newNumbers[index] = count % 10;
          count++;
          return newNumbers;
        });
      }, 100); // Change number every 150ms for slow rolling

      // Stop rolling after 2 seconds and display the correct number
      setTimeout(() => {
        clearInterval(intervalId);
        setRollingNumbers((prevNumbers) => {
          const newNumbers = [...prevNumbers];
          newNumbers[index] = item.number; // Set the final correct number
          return newNumbers;
        });
        setIsRolling(false); // Stop rolling after 2 seconds
      }, 2000); // Rolling continues for 2 seconds before stopping
    }); // eslint-disable-next-line
  }, []); // Run only on component mount

  return (
    <>
      <Flex
        direction="row"
        justify="center"
        items="center"
        className="pl-8 pr-8 pt-2 pb-2 rounded-3xl min-w-[350px] border-2 border-t-8 border-t-yellow-400 shadow-md shadow-gray-400 border-blue-950 mt-5">
        {chartItems.map((item, index) => (
          <Flex
            direction="col"
            key={index}
            align="center"
            justify="between"
            className="md:m-3 m-1 mobile-scale-08 hero-chart-container">
            <Image
              src={item.image}
              alt={item.title}
              width="51px"
              height="51px"
              className="mb-3 mobile-scale-08"
            />
            <Text
              content={item.title}
              size="12px"
              weight="400"
              className="text-center md:text-sm mobile-scale-08"
            />
            <div className="rolling-number  mobile-scale-08 -z-10 hero-chart-items">
              {/* Add 'stopped' class when rolling stops */}
              <span className={!isRolling ? "stopped" : "rolling"}>
                {rollingNumbers[index]}
              </span>
            </div>
          </Flex>
        ))}
      </Flex>
      <div className="w-full border-b-2 border-b-gray-200 border-dashed mt-8"></div>
    </>
  );
}

export default HeroChart;
