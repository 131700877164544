import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const Icon = ({
  IconComponent,
  size = "24px",
  color = "white",
  className = "",
  ...props // Allows spreading any other props (like onClick, aria-label, etc.)
}) => {
  return (
    <IconComponent
      style={{ height: size, width: size, color: color }}
      className={className}
      {...props} // Spread additional props for flexibility
    />
  );
};

// Define prop types for better validation and maintenance
Icon.propTypes = {
  IconComponent: PropTypes.elementType.isRequired, // IconComponent must be a valid React component
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
