import React from "react";
import { ManagementStudiesHeading } from "../components";
import CommerceSectionsTemplate from "../components/commerce/CommerceSectionsTemplate";
import {
  waiWaiLin,
  eiEiPhyo,
  eieiTheint,
  mayMyatMon,
  moeMoeNyein,
  nyeinEiHlaing,
  nyeinThetWai,
  nyeinThiriZaw,
  nyoHninHtwe,
  shweSinWin,
  suNanDarKyaw,
  waiYeeAung,
  suMyatAye,
  // zinMarNwe,
  dmsHead,
  mbaActi1,
  mbaActi2,
  mbaActi4,
  mbaActi5,
  mbaActi6,
  mbaActi7,
  mbaActi8,
  mbaActi9,
} from "../assets";

const ManagementStudiesDepartment = () => {
  const curriculums = [
    {
      title: "Bachelor of Business Administration (BBA) & BBA (Honours)",
      degrees: [
        "Myanmar",
        "Business English",
        "Business Mathematics ",
        "Introductory Microeconomics",
        "Fundamental of Statistics I",
        "Business Organization",
        "Aspects of Myanmar",
        "New Economic Geography",
        "Introductory Macroeconomics",
        "Fundamental of Statistics II",
        "Business Accounting",
        "General Management",
        "Financial Accounting I",
        "Decision Science",
        "Principle of Marketing",
        "Applied Statistics I ",
        "Financial Accounting II",
        "Managerial Accounting I",
        "Production and Operations Management",
        "Fundamentals of Human Resource Management",
        "Applied Statistics II",
        "Leadership",
        "Marketing Management",
        "Business Ethics",
        "Business Communication",
        "Business Research Methodology",
        "Business Law",
        "Managerial Accounting II",
        "Consumer Behavior",
        "Human Resource Management",
        "Introduction to Finance",
        "Personal Development",
        "Supply Chain Management",
        "Strategic Management",
        "Entrepreneurship and Small Business Management",
        "Research Project: Proposal and Preparation",
        "International Business Management",
        "Auditing/Public Sector Accounting",
        "Research Project: Submitting and Evaluation",
        "Corporate Strategy",
        "Corporate Finance",
        "Information Technology for Business ",
        "Organizational Behavior",
        "Corporate Governance",
        "Marketing Research",
        "Cross Culture Communication",
        "Service Management",
        "Banking and Financial Institutions",
        "Change Management",
        "Quantitative and Analytical Skills",
        "Project Management",
      ],
    },
    {
      title: "Master of Business Administration (MBA)",
      degrees: [
        "General Management",
        "Macroeconomic Environment",
        "Mathematics and Statistics for Management",
        "Managerial Accounting ",
        "Legal Framework for Business",
        "Human Resource Management",
        "Production & Operations Management",
        "Financial Management",
        "Entrepreneurship & Small Business Management",
        "Marketing Management",
        "Strategic Management",
        "Management Information Systems",
        "International Business Management",
        "Logistics & Supply Chain Management",
        "Organizational Design and Development",
        "Service Management",
        "Change Management",
        "Customer Relationship Management",
        "Thesis",
      ],
    },
  ];

  const headInfo = {
    name: "Dr. Su Myat Aye",
    profileImage: dmsHead,
    department: "Department of Management Studies",
    position: "Associate Professor ",
    passage:
      "Dr. Su Myat Aye obtained her B.Com (Hons;) degree in 2004 and M.Com degree in 2006 from Monywa University of Economics. She also holds the Doctoral degree in Commerce from Monywa University of Economics in 2016. She also received the Certificates of OCS Train the Trainers Programme provided by CIMA and Capacity Building & Training of Trainer on Pedagogy. She has served for Monywa University of Economics as Tutor (from 1st September 2009 to 11th August 2016), Assistant Lecturer (from 12th August 2016 to 1st December 2019), Lecturer (from 2nd December 2019 to 12th October 2021), and Associate Professor (from 13th October 2021 to Today). Her teaching and research experiences are Strategic Management, Management Accounting, Customer Relationship Management, Risk Management in Financial Institutions, Corporate Finance, and Marketing Management. She is currently taking the responsibility of Director of MBA Programme and BBA Programme in Monywa University of Economics.",
  };
  const members = [
    [
      {
        name: "Dr. Su Myat Aye",
        position: "Associate Professor ",
        profile: suMyatAye,
      },
    ],
    [
      {
        name: "Daw Zin Mar Nwe",
        position: "Lecturer",
        profile: waiWaiLin,
      },
    ],

    [
      {
        name: "Daw Shwe Sin Win",
        position: "Assistant Lecturer",
        profile: shweSinWin,
      },
    ],

    [
      {
        name: "Daw Wai Yee Aung",
        position: "Tutor",
        profile: waiYeeAung,
      },
      {
        name: "Daw Su Nandar Kyaw",
        position: "Tutor",
        profile: suNanDarKyaw,
      },
      {
        name: "Daw Nyo Hnin Htwe",
        position: "Tutor",
        profile: nyoHninHtwe,
      },
      {
        name: "Daw Nyein Thiri Zaw",
        position: "Tutor",
        profile: nyeinThiriZaw,
      },
      {
        name: "Daw Nyein Thet Wai",
        position: "Tutor",
        profile: nyeinThetWai,
      },
      {
        name: "Daw Moe Moe Nyein",
        position: "Tutor",
        profile: moeMoeNyein,
      },
      {
        name: "Daw May Myat Mon",
        position: "Tutor",
        profile: mayMyatMon,
      },
      {
        name: "Daw Ei Ei Theint",
        position: "Tutor",
        profile: eieiTheint,
      },
      {
        name: "Daw Ei Ei Phyo",
        position: "Tutor",
        profile: eiEiPhyo,
      },
    ],
    [
      {
        name: "Daw Nyein Ei Hlaing",
        position: "Undefined",
        profile: nyeinEiHlaing,
      },
    ],
  ];
  const degrees = [
    "Bachelor of Business Administration (BBA)",
    "Bachelor of Business Administration Honours (BBA Hons;)",
    "Master of Business Administration (MBA)",
  ];
  const programs = null;
  const facultyResearch = [
    {
      name: "Dr. Kyi Kyi Thant  (Professor/ Head ) ",
      research: [
        "Determinants of job satisfaction among employees in the Meiktila Industrial Zone, Myanmar. Journal of the Myanmar Academy of Arts and Science, 10(11), 215-230. (2012)",
        "Employee perception towards human resource management practices of hotels in Bagan. Meiktila University of Economics Research Journal, 7(1), 57-66. (2016)",
        "Organizational learning of private training schools in Mandalay. Meiktila University of Economics Research Journal, 8(1), 61-69. (2017)",
        "Information system development of hotels in Bagan. Myanmar Universities’ Research Conference 2019, Conference Proceedings, 1(2), 150-154. (2019)",
        "Innovation of weaving firms in Wundwin Township. Meiktila University of Economics Research Journal, 9(1), 169-177. (2019)",
        "Information system adoption of private hospitals in Mandalay Region. University Journal of Research and Innovation (University of Computer Studies, Pakokku), 1(1), 121-126. (2019)",
        "Information system development of hotels in Naypyitaw, Myanmar. Journal of the Myanmar Academy of Arts and Science, Special Issue, 157-164. (2019)",
        "Driving forces and outcomes of information system development in Hotels, Bagan. Meiktila University of Economics Research Journal, 10(1), 95-109. (2020)",
        "Competitive advantage through innovation of hotels in Nay Pyi Taw. The Second Myanmar Universities’ Research Conference (2020) Conference Proceedings, 2(2), 72-77. (2020)",
        "Entrepreneurial Competencies of garment manufacturers in Wundwin Township. Journal of the Myanmar Academy of Arts and Science, 18(8), 29-37. (2020)",
        "Factors influencing the adoption of mobile payment: An empirical analysis. Meiktila University of Economics, 10(2), 97-109. (2020)",
        "Employee commitment to private schools in Meiktila Township, Universities Research Journal, 12(8), 283-296. (2020)",
        "Resources and competitive advantage of weaving firms in Wundwin Township. The Myanmar Journal, 8(1), 214-223. (2021)",
        "Information system development of hotels in Mandalay. Journal of the Myanmar Academy of Arts and Science, 19(8), 25-39. (2021)",
        "Workplace stress and burnout among employees in the banking industry, Myanmar. International Journal of Business, Economics and Law, 25(2), 24-30. (2021)",
        "Behavioral intention to use e-learning: Application of technology acceptance model. Journal of Education and Social Sciences, 22(1), 20-26. (2022)",
        "Challenge and hindrance stressors of employees in the banking industry, Myanmar. Meiktila University of Economics Research Journal, 12(1), 49-61. (2023)",
        "Internal marketing and employee job satisfaction in CB Bank in Pyinmana Township. Meiktila University of Economics Research Journal, 12(1), 126-137. (2023)",
      ],
    },
    {
      name: "Dr. Ye Wint Aung  ( Lecture )",
      research: [
        "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay Ph.D Commerce 2020, July.",
        "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2020, March Vol.11, No. 2 21-44.",
        "Effect of Participants Characteristics on Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2023, March Vol.13.",
        "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.13.",
        "Consumers Behaviors towards Single-Brand Apparel Retailers in Mandalay Myanmar MUE Research Journal 2024, March Vol.14.",
      ],
    },
    {
      name: "Daw Sie Sar Oo  ( Assistant Lecturer )",
      research: [
        "Psychological Empowerment and Employee Performance at KMA Hotel Group in Bagan Research Paper Reading Seminar in Commemoration of the 23rd Anniversary of Monywa, Oct 1-2/2019 Pg.255-259.",
        "Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2020, March Vol.11, No. 2 21-44.",
        "Effect of Participants Characteristics on Consumers Attitude towards Single-Brand Apparel Retailers in Mandalay MUE Research Journal 2023, March Vol.13.",
      ],
    },
    {
      name: "Daw Shoon Lae Wai  ( Tutor )",
      research: [
        "The Impact of Employee Relationship Management on Employee Satisfaction and Retention at Cane Firm in Sagaing MUE Research Journal 2022.",
        "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.12 111-125.",
        "Relationship Marketing Practices and Customer Loyalty at AYA Bank in Monywa MUE Research Journal 2024, March Vol.14 112-129.",
      ],
    },
    {
      name: "Daw Yu Mon Aung  ( Tutor )",
      research: [
        "Relationship Marketing Practices and Customer Loyalty at AYA Bank in Monywa MUE Research Journal 2024, March Vol.14 112-129.",
      ],
    },
    {
      name: "Daw May Thu Khaing  ( Tutor )",
      research: [
        "Internal Marketing Practices, Job Satisfaction and Organizational Commitment at Global Treasure Bank MUE Research Journal 2023, March Vol.12 95-110.",
        "Transfer of Training and University Teachers Performance in Monywa MUE Research Journal 2023, March Vol.12 126-142.",
        "Determinants of Students Perceived Learning and Satisfaction in Online Learning MUE Research Journal 2023, March Vol.14 198-211.",
      ],
    },
    {
      name: "Daw Ei Thandar Aung  ( Tutor )",
      research: [
        "Transfer of Training and University Teachers Performance in Monywa MUE Research Journal 2023, March Vol.12 126-142.",
        "Internal Marketing Practices, Job Satisfaction and Organizational Commitment at Global Treasure Bank MUE Research Journal 2023, March Vol.12 95-110.",
        "Organizational Change and Employee Performance of Internal Revenue Department MUE Research Journal 2023, March Vol.12 158-169.",
        "Retail Service Quality and Customer Loyalty of Ocean Supermarket MUE Research Journal 2023, March Vol.14 246-259.",
      ],
    },
    {
      name: "Daw Yin Kyawt Mon  ( Tutor )",
      research: [
        "The Impact of Employee Relationship Management on Employee Satisfaction and Retention at Cane Firm in Sagaing MUE Research Journal 2022.",
        "Marketing Communication and Repurchase Intention towards KFC Myanmar MUE Research Journal 2023, March Vol.12 111-125.",
      ],
    },
  ];

  const activities = [
    { title: "MBA  အာစရိယပူဇော်ပွဲ", image: mbaActi1 },
    { title: "MBA Gathering", image: mbaActi2 },
    { title: "Staff Development", image: mbaActi4 },
    { title: "ကဆုန်ညောင်ရေသွန်းပွဲတော်", image: mbaActi5 },
    { title: "တိုင်းရင်းသားရိုးရာ ဈေးရောင်းပွဲတော်", image: mbaActi6 },
    { title: "ထမနဲပွဲ အမှတ်တရ စီမံခန့်ခွဲမှုပညာဌာန", image: mbaActi7 },
    { title: "ပြေးခုန်ပစ်ပြိုင်ပွဲ", image: mbaActi8 },
    { title: "လွတ်လပ်ရေးနေ့ အခမ်းအနား", image: mbaActi9 },
  ];

  return (
    <>
      <div className="ml-32 mr-12 mobile-margin-x-zero">
        <ManagementStudiesHeading />
        <CommerceSectionsTemplate
          curriculumsTypes={curriculums}
          headMaster={headInfo}
          groupMembers={members}
          researches={facultyResearch}
          news={activities}
          offeredDegrees={degrees}
          hrdProgrammes={programs}
        />
      </div>
    </>
  );
};

export default ManagementStudiesDepartment;
