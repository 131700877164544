import React from "react";
import { PiDiamondsFourFill } from "react-icons/pi";

const CommerceDegreeOffered = ({ degrees, programmes }) => {
  return (
    <>
      <div className="m-4 w-full p-8 rounded-3xl border-2 border-l-8 border-l-yellow-400  border-blue-950">
        <p className="text-xl text-left font-medium mb-6">Degree Offered</p>
        {degrees.map((degree, index) => (
          <>
            <p
              className="text-xl font-medium mb-2 flex flex-row gap-3"
              key={index}>
              <PiDiamondsFourFill size={20} /> {degree}
            </p>
          </>
        ))}
      </div>
      {programmes && (
        <div className="m-4 w-full p-8 rounded-3xl border-2  border-l-8 border-l-yellow-400  border-blue-950">
          <p className="text-xl text-left mb-6 font-medium ">
            HRD Programmers (Online)
          </p>
          {programmes.map((programme, index) => (
            <>
              <p
                className="text-xl font-medium mb-2 flex flex-row gap-3"
                key={index}>
                <PiDiamondsFourFill size={20} /> {programme}
              </p>
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default CommerceDegreeOffered;
