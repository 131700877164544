import {
  About,
  Degree,
  // ContactUs,
  Hero,
  // Products,
  // Services,
  // Solutions,
  Contact,
  Campus,
  MasterPlan,
  Organization,
  Prospectus,
  Admission,
  CommerceDepartment,
  EconomicsDepartment,
  AppliedEcoDepartment,
  ManagementStudiesDepartment,
  StatisticsDepartment,
  LibraryMyanmar,
  LibraryEnglish,
  Activity2025,
  Bachelor,
  Master,
  MouMoa,
  Project,
  Maas,
  Urj,
  International,
  PhdPlan,
  ResearchMaster,
  Myanmar,
  English,
  Mathematics,
  Geography,
  AdminDepartment,
  FinanceDepartment,
  ICTDepartment,
  StudentAffairs,
  Sport,
  Library,
} from "./pages";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Activities24 } from "./components";

const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/" element={<Hero />} />
      <Route path="/about" element={<About />} />
      <Route path="/degree" element={<Degree />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/campus" element={<Campus />} />
      <Route path="/master-plan" element={<MasterPlan />} />
      <Route path="/Organization" element={<Organization />} />
      <Route path="/prospectus" element={<Prospectus />} />
      <Route path="/admission" element={<Admission />} />
      <Route path="/activities/2024" element={<Activities24 />} />
      <Route path="/activities/2025" element={<Activity2025 />} />
      <Route
        path="/academic-department/main/commerce"
        element={<CommerceDepartment />}
      />
      <Route
        path="/academic-department/main/economics"
        element={<EconomicsDepartment />}
      />
      <Route
        path="/academic-department/main/applied-economics"
        element={<AppliedEcoDepartment />}
      />
      <Route
        path="/academic-department/main/management"
        element={<ManagementStudiesDepartment />}
      />
      <Route
        path="/academic-department/main/statistics"
        element={<StatisticsDepartment />}
      />
      <Route path="/library/myanmar" element={<LibraryMyanmar />} />
      <Route path="/library/english" element={<LibraryEnglish />} />
      <Route path="/admission/bachelor" element={<Bachelor />} />
      <Route path="/admission/master" element={<Master />} />
      <Route path="/mou-moa" element={<MouMoa />} />
      <Route path="/project" element={<Project />} />
      <Route path="/research">
        <Route path="maas" element={<Maas />} />
        <Route path="urj" element={<Urj />} />
        <Route path="international" element={<International />} />
        <Route path="ph-d" element={<PhdPlan />} />
        <Route path="master" element={<ResearchMaster />} />
      </Route>
      <Route path="/academic-department/supporting">
        <Route path="myanmar" element={<Myanmar />} />
        <Route path="english" element={<English />} />
        <Route path="mathematics" element={<Mathematics />} />
        <Route path="geography" element={<Geography />} />
      </Route>
      <Route path="/administrative-department/administration">
        <Route path="admin" element={<AdminDepartment />} />
        <Route path="finance" element={<FinanceDepartment />} />
        <Route path="ict" element={<ICTDepartment />} />
      </Route>
      <Route path="/administrative-department/academic-affairs">
        <Route path="student-affairs" element={<StudentAffairs />} />
        <Route path="sport" element={<Sport />} />
        <Route path="library" element={<Library />} />
      </Route>
    </Routes>
  );
};

export default RouterConfig;
